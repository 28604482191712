import React, { useEffect, useState } from "react";
import {
  getStripeKeys,
  createOrUpdateStripeKeys,
} from "../services/apiService"; // Import your service functions
import ButtonLoader from "../components/Loaders/ButtonLoader";
import { toast, ToastContainer } from "react-toastify";

const StripeKeyManager = () => {
  const [publishableKey, setPublishableKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [webHookSecret, setWebHookSecret] = useState("");
  const [loading, setLoading] = useState(false);

  // Fetch the Stripe keys when the component loads
  useEffect(() => {
    const fetchStripeKeys = async () => {
      setLoading(true);
      try {
        const data = await getStripeKeys();
        if (data) {
          setPublishableKey(data.publishableKey);
          setSecretKey(data.secretKey);
          setWebHookSecret(data.webHookSecret);
        }
      } catch (error) {
        toast.error("Error fetching Stripe keys");
      } finally {
        setLoading(false);
      }
    };
    fetchStripeKeys();
  }, []);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await createOrUpdateStripeKeys({
        publishableKey,
        secretKey,
        webHookSecret,
      });
      toast.success("Stripe keys updated successfully");
    } catch (error) {
      toast.error("Failed to update Stripe keys");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-md shadow-md">
      <ToastContainer position="top-right" autoClose={5000} />
      <h2 className="text-xl font-bold mb-4">Manage Stripe Keys</h2>

      {loading ? (
        <ButtonLoader />
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-sm font-bold mb-2"
              htmlFor="publishableKey"
            >
              Publishable Key
            </label>
            <input
              type="text"
              id="publishableKey"
              value={publishableKey}
              onChange={(e) => setPublishableKey(e.target.value)}
              className="w-full px-3 py-2 text-gray-700 border rounded-lg"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-bold mb-2" htmlFor="secretKey">
              Secret Key
            </label>
            <input
              type="text"
              id="secretKey"
              value={secretKey}
              onChange={(e) => setSecretKey(e.target.value)}
              className="w-full px-3 py-2 text-gray-700 border rounded-lg"
              required
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-bold mb-2"
              htmlFor="webHookSecret"
            >
              Webhook secret
            </label>
            <input
              type="text"
              id="webHookSecret"
              value={webHookSecret}
              onChange={(e) => setWebHookSecret(e.target.value)}
              className="w-full px-3 py-2 text-gray-700 border rounded-lg"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full py-2 px-4 bg-purpleButton text-white font-bold rounded-lg"
            disabled={loading}
          >
            {loading ? <ButtonLoader /> : "Update Keys"}
          </button>
        </form>
      )}
    </div>
  );
};

export default StripeKeyManager;

import React, { useRef, useState, useEffect } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { getStatistics } from "../../services/apiService"; // Import the API function
import { useDispatch } from "react-redux";
import ButtonLoader from "../Loaders/ButtonLoader"; // Import your custom ButtonLoader

// Import images
import creditsPurchasedIcon from "../../images/statistics/creditsPurchased.png";
import totalSubscriptionIcon from "../../images/statistics/totalSubscription.png";
import companiesQtyIcon from "../../images/statistics/companiesQWT.png";
import candidatesQtyIcon from "../../images/statistics/CandidatesQTY.png";
import activeJobsQtyIcon from "../../images/statistics/ActiveJobQ.png";
import totalJobsQtyIcon from "../../images/statistics/totalJobQ.png";

const StatsComponent = () => {
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(true); // State for loading

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const data = await dispatch(getStatistics());
        setStats([
          {
            icon: creditsPurchasedIcon,
            value: data.totalCreditsPurchased,
            label: "Credits purchased",
          },
          {
            icon: totalSubscriptionIcon,
            value: `$${data.totalSubscriptionCost}`,
            label: "Total subscription",
          },
          {
            icon: companiesQtyIcon,
            value: data.employers,
            label: "Companies qty",
          },
          {
            icon: candidatesQtyIcon,
            value: data.candidates,
            label: "Candidates qty",
          },
          {
            icon: activeJobsQtyIcon,
            value: data.activeJobs,
            label: "Active jobs qty",
          },
          {
            icon: totalJobsQtyIcon,
            value: data.totalJobQuantities,
            label: "Total jobs qty",
          },
        ]);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch statistics", error);
        setLoading(false);
      }
    };

    fetchStatistics();
  }, [dispatch]);

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  return (
    <div className="relative w-full max-w-6xl mx-auto p-4">
      {loading ? (
        <ButtonLoader /> // Use your custom loader while loading
      ) : (
        <>
          <div className="absolute left-0 top-1/2 transform -translate-y-1/2 lg:hidden">
            <button
              onClick={scrollLeft}
              className="p-2 bg-gray-200 rounded-full"
            >
              <FaArrowLeft />
            </button>
          </div>
          <div className="absolute right-0 top-1/2 transform -translate-y-1/2 lg:hidden">
            <button
              onClick={scrollRight}
              className="p-2 bg-gray-200 rounded-full"
            >
              <FaArrowRight />
            </button>
          </div>
          <div
            ref={containerRef}
            className="flex overflow-x-auto space-x-4"
            style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
          >
            {stats.map((stat, index) => (
              <div
                key={index}
                className="flex-shrink-0 w-48 sm:w-auto flex flex-col items-center p-4 bg-white rounded-lg shadow-md"
              >
                <div className="mb-2 p-4 bg-green-100 rounded-full">
                  <img src={stat.icon} alt={stat.label} className="w-10 h-10" />
                </div>
                <div className="text-2xl font-bold">{stat.value}</div>
                <div className="text-gray-600">{stat.label}</div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default StatsComponent;

// src/SettingsForm.js
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import ButtonLoader from "../components/Loaders/ButtonLoader";
import {
  editTestPermissions,
  getTestPermissions,
} from "../services/apiService"; // Import your action creators

const TestPermissions = () => {
  const dispatch = useDispatch();
  const testPermissions = useSelector(
    (state) => state.testPermissionsReducer_admin.testPermissions[0]
  );
  const [multiChoiceQuestions, setMultiChoiceQuestions] = useState(
    testPermissions?.multiChoiceQuestions || ""
  );
  const [openQuestionWords, setOpenQuestionWords] = useState(
    testPermissions?.openQuestionWords || ""
  );
  const [fileDataMax, setFileDataMax] = useState(
    testPermissions?.fileDataMax || ""
  );
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  // Memoize fetchTestPermissions to prevent unnecessary re-creation on every render
  const fetchTestPermissions = useCallback(async () => {
    try {
      setLoading(true);
      await dispatch(getTestPermissions());
      toast.success("Test permissions fetched successfully!");
    } catch (error) {
      toast.error("Failed to fetch test permissions.");
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    if (!testPermissions) {
      // Check if test permissions are already in Redux store
      fetchTestPermissions();
    } else {
      // Set state from Redux store if available
      setMultiChoiceQuestions(testPermissions.multiChoiceQuestions);
      setOpenQuestionWords(testPermissions.openQuestionWords);
      setFileDataMax(testPermissions.fileDataMax);
    }
  }, [testPermissions, fetchTestPermissions]); // Include fetchTestPermissions in the dependency array

  const editTestPermission = async (e) => {
    e.preventDefault();

    // Validate input values before submitting
    if (multiChoiceQuestions <= 0 || openQuestionWords <= 0 || fileDataMax <= 0) {
      toast.error("All input values must be greater than 0.");
      return;
    }

    setButtonLoading(true);
    try {
      await dispatch(
        editTestPermissions({
          multiChoiceQuestions,
          openQuestionWords,
          fileDataMax,
        })
      );
      toast.success("Test permissions updated successfully!");
    } catch (error) {
      toast.error("Failed to update test permissions.");
    } finally {
      setButtonLoading(false);
    }
  };

  if (loading) {
    return <ButtonLoader />;
  }

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg w-full mx-auto">
      <ToastContainer />
      <form className="space-y-6" onSubmit={editTestPermission}>
        <div className="flex">
          <div className="flex flex-col items-start justify-between mb-4">
            <label className="block text-gray-700 font-bold mb-2">
              Multi choice questions
            </label>
            <p className="mt-1 text-sm text-gray-500 max-w-32">
              Enter numbers of maximum allowed Multiple questions
            </p>
          </div>
          <div className="flex items-center">
            <input
              type="number"
              className="w-28 px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
              value={multiChoiceQuestions}
              onChange={(e) =>
                setMultiChoiceQuestions(Math.max(Number(e.target.value), 0))
              } // Ensure value is >= 0
              min="1" // Restrict input to values greater than or equal to 1
            />
          </div>
        </div>
        <div className="flex gap-10">
          <div className="flex flex-col items-start justify-between mb-4">
            <label className="block text-gray-700 font-bold mb-2">
              Open questions
            </label>
            <p className="mt-1 text-sm text-gray-500 max-w-32">
              Enter number maximum allowed in words
            </p>
          </div>
          <div className="flex items-center">
            <input
              type="number"
              className="w-28 px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
              value={openQuestionWords}
              onChange={(e) =>
                setOpenQuestionWords(Math.max(Number(e.target.value), 0))
              } // Ensure value is >= 0
              min="1" // Restrict input to values greater than or equal to 1
            />
          </div>
        </div>
        <div className="flex gap-10">
          <div className="flex flex-col items-start justify-between mb-4">
            <label className="block text-gray-700 font-bold mb-2">
              File questions
            </label>
            <p className="mt-1 text-sm text-gray-500 max-w-32">
              Max Mb allowed to file upload
            </p>
          </div>
          <div className="flex items-center">
            <input
              type="number"
              className="w-28 px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
              value={fileDataMax}
              onChange={(e) =>
                setFileDataMax(Math.max(Number(e.target.value), 0))
              } // Ensure value is >= 0
              min="1" // Restrict input to values greater than or equal to 1
            />
          </div>
        </div>
        <button
          type="submit"
          className="px-10 py-2 text-white bg-purpleButton rounded-full hover:bg-blue-700 focus:outline-none"
          disabled={buttonLoading}
        >
          {buttonLoading ? <ButtonLoader /> : "Save"}
        </button>
      </form>
    </div>
  );
};

export default TestPermissions;

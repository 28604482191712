import React, { useState, useEffect } from "react";
import { FiMinus } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ButtonLoader from "../components/Loaders/ButtonLoader"; // Import your custom ButtonLoader
import {
  employerAdmin,
  toggleBlockUnBloackEmployer,
  softDeleteEmployer,
} from "../services/apiService"; // Import the API functions

const getStatusClass = (status) => {
  switch (status) {
    case true:
      return "text-red-600";
    case false:
      return "text-green-600";
    default:
      return "text-green-600";
  }
};

function Employer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [expandedRow, setExpandedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [candidatesPerPage, setCandidatesPerPage] = useState(10);
  const [filterStatus, setFilterStatus] = useState(null); // Default to 'All' filter
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [loading, setLoading] = useState(true);
  const [employers, setEmployers] = useState([]);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [loadingButton, setLoadingButton] = useState(null); // To track specific button loading

  useEffect(() => {
    const fetchEmployers = async () => {
      setLoading(true);
      try {
        const data = await dispatch(
          employerAdmin({
            page: currentPage,
            limit: candidatesPerPage,
            filter: filterStatus !== null ? filterStatus : 0,
          })
        );
        const formattedEmployers = data.employers.map((employer) => ({
          srNo: employer._id,
          name: `${employer.first_name} ${employer.last_name}`,
          credit:
            employer.currentSubscription?.currentPackage?.packageStatus
              ?.numberOfCredits || 0,
          email: employer.email,
          isBlocked: employer.isBlocked,
        }));

        setEmployers(formattedEmployers);
        setTotalDocuments(data.pagination.totalDocuments);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch employers", error);
        setLoading(false);
      }
    };

    fetchEmployers();
  }, [dispatch, currentPage, candidatesPerPage, filterStatus]);

  const handleRowExpand = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const handleCandidatesPerPageChange = (e) => {
    setCandidatesPerPage(Number(e.target.value));
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleFilterChange = (e) => {
    const status = e.target.value;
    setFilterStatus(Number(status));
    setCurrentPage(1);
  };

  const handleDropdownToggle = (index) => {
    setDropdownIndex(dropdownIndex === index ? null : index);
  };

  const handleToggleBlock = async (id, index) => {
    setLoadingButton(`block-${index}`); // Track the button being clicked
    try {
      await dispatch(toggleBlockUnBloackEmployer({ id }));
      let Employer = employers.map((item) => {
        if (item.srNo === id) {
          return { ...item, isBlocked: !item.isBlocked };
        } else {
          return item;
        }
      });
      setEmployers(Employer);
    } catch (error) {
      console.error("Failed to toggle employer status", error);
    } finally {
      setLoadingButton(null); // Reset loading state
    }
  };

  const handleDelete = async (id, index) => {
    setLoadingButton(`delete-${index}`); // Track the button being clicked
    try {
      await dispatch(softDeleteEmployer({ id }));
      let Employers = employers.filter((item) => item.srNo !== id);

      setEmployers(Employers);
    } catch (error) {
      console.error("Failed to delete employer", error);
    } finally {
      setLoadingButton(null); // Reset loading state
    }
  };

  const handleEdit = (id, email, credit) => {
    navigate("/index/editEmployer/", {
      state: {
        id,
        email,
        credit,
      },
    });
  };

  return (
    <div className="rounded-lg p-4 bg-white shadow">
      <div className="flex justify-between items-center mb-4">
        <div className="flex gap-2">
          <select
            className="p-2 border rounded bg-gray-100"
            onChange={handleFilterChange}
          >
            <option value="0">All</option>
            <option value="1">Enabled</option>
            <option value="2">Disabled</option>
          </select>
        </div>
      </div>
      {loading ? (
        <ButtonLoader /> // Show loader while fetching data
      ) : (
        <>
          {/* Desktop and Tablet */}
          <div className="hidden md:flex flex-col">
            <table className="min-w-full text-xs lg:text-sm">
              <thead>
                <tr className="text-left text-gray-400">
                  <th className="p-2">ID</th>
                  <th className="p-2">Employer</th>
                  <th className="p-2">Credit</th>
                  <th className="p-2">Status</th>
                  <th className="p-2">Transaction History</th>
                  <th className="p-2">Action</th>
                </tr>
              </thead>
              <tbody className="text-gray-700">
                {employers.map((candidate, index) => (
                  <tr key={index} className="border-t">
                    <td className="p-2 font-bold">{candidate.srNo}</td>
                    <td className="p-2">
                      <div className="font-bold">{candidate.name}</div>
                      <div className="text-gray-500">{candidate.email}</div>
                    </td>
                    <td className="p-2">
                      {!candidate.credit ? 0 : candidate.credit}
                    </td>
                    <td className="p-2">
                      <span
                        className={`px-4 py-1 rounded-2xl ${getStatusClass(
                          candidate.isBlocked
                        )}`}
                      >
                        {!candidate.isBlocked ? "Enabled" : "Disabled"}
                      </span>
                    </td>
                    <td className="p-2">
                      <button
                        className="text-blue-500 hover:underline"
                        onClick={() => {
                          navigate("/index/transactionHistoryEmployer", {
                            state: {
                              id: candidate.srNo,
                            },
                          });
                        }}
                      >
                        View Transaction History
                      </button>
                    </td>
                    <td className="p-2 relative">
                      <BsThreeDotsVertical
                        className="cursor-pointer"
                        onClick={() => handleDropdownToggle(index)}
                      />
                      {dropdownIndex === index && (
                        <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-10">
                          <div
                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                            onClick={() =>
                              handleEdit(
                                candidate.srNo,
                                candidate.email,
                                candidate.credit
                              )
                            }
                          >
                            Edit
                          </div>
                          <div
                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                            onClick={() =>
                              handleToggleBlock(candidate.srNo, index)
                            }
                          >
                            {loadingButton === `block-${index}` ? (
                              <ButtonLoader />
                            ) : candidate.isBlocked ? (
                              "Enable"
                            ) : (
                              "Disable"
                            )}
                          </div>
                          <div
                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => handleDelete(candidate.srNo, index)}
                          >
                            {loadingButton === `delete-${index}` ? (
                              <ButtonLoader />
                            ) : (
                              "Delete"
                            )}
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Mobile */}
          <div className="flex md:hidden flex-col">
            <table className="min-w-full text-xs lg:text-sm">
              <thead>
                <tr className="text-left text-gray-400">
                  <th className="p-2">ID</th>
                  <th className="p-2">Action</th>
                </tr>
              </thead>
              <tbody className="text-gray-700">
                {employers.map((candidate, index) => (
                  <React.Fragment key={index}>
                    <tr className="border-t">
                      <td className="p-2 font-bold">
                        <div className="flex items-center">
                          {expandedRow === index ? (
                            <div className="flex items-center">
                              <div
                                className="cursor-pointer p-1 rounded-full bg-red-500 text-white"
                                onClick={() => handleRowExpand(index)}
                              >
                                <FiMinus />
                              </div>
                            </div>
                          ) : (
                            <div className="flex items-center">
                              <div
                                className="cursor-pointer p-1 rounded-full bg-purpleButton text-white"
                                onClick={() => handleRowExpand(index)}
                              >
                                <GoPlus />
                              </div>
                            </div>
                          )}
                          <div className="ml-2">{candidate.srNo}</div>
                        </div>
                      </td>
                      <td className="p-2 relative">
                        <BsThreeDotsVertical
                          className="cursor-pointer"
                          onClick={() => handleDropdownToggle(index)}
                        />
                        {dropdownIndex === index && (
                          <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-10">
                            <div
                              className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() =>
                                handleEdit(
                                  candidate.srNo,
                                  candidate.email,
                                  candidate.credit
                                )
                              }
                            >
                              Edit
                            </div>
                            <div
                              className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() =>
                                handleToggleBlock(candidate.srNo, index)
                              }
                            >
                              {loadingButton === `block-${index}` ? (
                                <ButtonLoader />
                              ) : candidate.isBlocked ? (
                                "Enable"
                              ) : (
                                "Disable"
                              )}
                            </div>
                            <div
                              className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() =>
                                handleDelete(candidate.srNo, index)
                              }
                            >
                              {loadingButton === `delete-${index}` ? (
                                <ButtonLoader />
                              ) : (
                                "Delete"
                              )}
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                    {expandedRow === index && (
                      <tr>
                        <td colSpan={2} className="p-2">
                          <div className="flex flex-col space-y-2">
                            <div>
                              <div className="font-bold">EMPLOYER:</div>
                              <div>{candidate.name}</div>
                              <div className="text-gray-500">
                                {candidate.email}
                              </div>
                            </div>
                            <div>
                              <div className="font-bold">Credit:</div>
                              {!candidate.credit ? 0 : candidate.credit}
                            </div>
                            <div>
                              <div className="font-bold">
                                Transaction History:
                              </div>
                              <button
                                className="text-blue-500 hover:underline"
                                onClick={() => {
                                  navigate(
                                    "/index/transactionHistoryEmployer",
                                    {
                                      state: {
                                        id: candidate.srNo,
                                      },
                                    }
                                  );
                                }}
                              >
                                View Transaction History
                              </button>
                            </div>
                            <div className="flex flex-col text-left gap-1">
                              <div className="font-bold">STATUS:</div>
                              <div>
                                <span
                                  className={`px-4 py-1 rounded-2xl items-center ${getStatusClass(
                                    candidate.isBlocked
                                  )}`}
                                >
                                  {!candidate.isBlocked
                                    ? "Enabled"
                                    : "Disabled"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex justify-center sm:justify-between items-center mt-4 text-sm">
            <div className="hidden sm:flex items-center space-x-2">
              <select
                className="p-2 border rounded bg-gray-100"
                onChange={handleCandidatesPerPageChange}
                value={candidatesPerPage}
              >
                <option value="2">2</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
              <span>
                Showing {employers.length > 0 ? 1 : 0} to{" "}
                {Math.min(employers.length)} of {totalDocuments} records
              </span>
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => paginate(currentPage - 1)}
                className={`px-2 py-1 rounded ${
                  currentPage === 1
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-gray-200 text-gray-600"
                }`}
                disabled={currentPage === 1}
              >
                <FaAngleLeft />
              </button>
              <button
                key={currentPage}
                onClick={() => paginate(currentPage)}
                className="px-3 py-1 rounded bg-purpleButton text-white"
              >
                {currentPage}
              </button>
              <button
                onClick={() => paginate(currentPage + 1)}
                className={`px-2 py-1 rounded ${
                  currentPage === Math.ceil(totalDocuments / candidatesPerPage)
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-gray-200 text-gray-600"
                }`}
                disabled={
                  currentPage === Math.ceil(totalDocuments / candidatesPerPage)
                }
              >
                <FaAngleRight />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Employer;

const initialState = {
  guidanceList: [], // List of help and guidance items
};

const employerHelpAndGuidanceReducer_admin = (state = initialState, action) => {
  switch (action.type) {
    case "STORE_EMPLOYER_HELP_GUIDE":
      return {
        guidanceList: [...state.guidanceList, ...action.payload],
      };

    case "EDIT_EMPLOYER_HELP_GUIDE":
      return {
        ...state,
        guidanceList: state.guidanceList.map((item) =>
          item._id === action.payload._id
            ? { ...item, ...action.payload }
            : item
        ),
      };
    case "EDIT_SORTED_EMPLOYER_HELP_GUIDE": {
      const currentItem = state.guidanceList.find(
        (item) => item._id === action.payload.id
      );
      const currentSort = currentItem.sort;

      let updatedGuidanceList;

      if (action.payload.sort <= currentSort) {
        updatedGuidanceList = state.guidanceList.map((item) => {
          if (item._id === action.payload.id) {
            return { ...item, sort: action.payload.sort };
          } else if (
            item.sort >= action.payload.sort &&
            item.sort < currentSort
          ) {
            return { ...item, sort: item.sort + 1 };
          }
          return item;
        });
      } else if (action.payload.sort > currentSort) {
        updatedGuidanceList = state.guidanceList.map((item) => {
          if (item._id === action.payload.id) {
            return { ...item, sort: action.payload.sort };
          } else if (
            item.sort > currentSort &&
            item.sort <= action.payload.sort
          ) {
            return { ...item, sort: item.sort - 1 };
          }
          return item;
        });
      }

      // Sort the updated list by the sort value
      updatedGuidanceList.sort((a, b) => a.sort - b.sort);
      return {
        ...state,
        guidanceList: updatedGuidanceList,
      };
    }

    case "DELETE_EMPLOYER_HELP_GUIDE":
      return {
        ...state,
        guidanceList: state.guidanceList.filter(
          (item) => item._id !== action.payload
        ),
      };

    case "GET_EMPLOYER_HELP_GUIDE":
      return {
        ...state,
        guidanceList: state.guidanceList.find(
          (item) => item.id === action.payload
        ),
      };

    default:
      return state;
  }
};

export default employerHelpAndGuidanceReducer_admin;

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateEmailCandidate } from "../services/apiService"; // Update with your correct import path
import { toast, ToastContainer } from "react-toastify"; // Import react-toastify for notifications
import { useLocation } from "react-router-dom"; // Import useLocation

const EditCandidate = () => {
  const location = useLocation(); // Hook to access the location object
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch candidate's email from location state or API
    if (location?.state?.email) {
      setEmail(location.state.email);
    }
  }, [location]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const payload = {
        candidateId: location?.state?.candidateId,
        newEmail: email,
      };

      await dispatch(updateEmailCandidate(payload));
      toast.success("Email updated successfully!");
    } catch (error) {
      console.error("Failed to update email", error);
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white shadow-md rounded-md">
      <ToastContainer />
      <h2 className="text-xl font-bold mb-4">Edit Candidate</h2>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              disabled={loading}
            >
              {loading ? "Updating..." : "Update"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditCandidate;

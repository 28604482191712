// src/redux/reducers/packageReducer.js

const initialState = {
  packages: [],
};

const packagesReducer_admin = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PACKAGES":
      return {
        ...state,
        packages: action.payload,
      };
    case "ADD_PACKAGE":
      return {
        ...state,
        packages: [...state.packages, ...action.payload],
      };
    case "EDIT_PACKAGE":
      return {
        ...state,
        packages: state.packages.map((pkg) =>
          pkg._id === action.payload._id ? action.payload : pkg
        ),
      };
    case "DELETE_PACKAGE":
      return {
        ...state,
        packages: state.packages.filter((pkg) => pkg._id !== action.payload),
      };
    default:
      return state;
  }
};

export default packagesReducer_admin;

import React, { useState, useEffect } from "react";
import InputField from "../../components/input/InputField";
import BackGroundAuth from "../../components/auth/BackGroundAuth";
import { FaArrowRight, FaEye, FaRegEyeSlash } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import OTPInput from "../../components/auth/OTPbox";
import ButtonLoader from "../../components/Loaders/ButtonLoader.jsx";
import {
  loginAdmin,
  getAdminProfile,
  sendForgetPassword,
  verifyOtp,
  resetPassword,
} from "../../services/apiService.js"; // Import your API actions

const LoginAdmin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [step, setStep] = useState(0);
  const [otp, setOtp] = useState("");
  const [otpLoading, setOtpLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [confirmedPasswordVisible, setConfirmedPasswordVisible] =
    useState(false);
  const [loading, setLoading] = useState(true); // Set initial loading state to true
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Attempt to get admin profile on component mount
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        await dispatch(getAdminProfile());
        navigate("/index");
      } catch (error) {
        console.log("Failed to fetch admin profile on login page:", error);
      } finally {
        setLoading(false); // Stop the loader after attempting to get the profile
      }
    };

    fetchProfile();
  }, [dispatch, navigate]);

  const handleLogin = async () => {
    setLoading(true);
    try {
      await dispatch(loginAdmin({ email, password }));
      toast.success("Logged in successfully");
      navigate("/index");
    } catch (error) {
      toast.error("Login failed: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSendOtp = async () => {
    setOtpLoading(true);
    try {
      await dispatch(sendForgetPassword({ email })); // Send OTP
      setStep(2);
      toast.success("OTP sent to your email.");
    } catch (error) {
      toast.error("Error sending OTP. Try again.");
    } finally {
      setOtpLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    setOtpLoading(true);
    try {
      await dispatch(verifyOtp({ email, otp })); // Verify OTP
      setStep(3);
      toast.success("OTP verified.");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setOtpLoading(false);
    }
  };

  const handlePasswordChange = async () => {
    if (confirmPassword !== confirmedPassword) {
      toast.error("Passwords do not match.");
      return;
    }
    try {
      await dispatch(resetPassword({ email, otp, password: confirmPassword })); // Reset password
      setStep(0);
      toast.success("Password reset successfully.");
    } catch (error) {
      toast.error(error.message);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <ButtonLoader />
      </div>
    );
  }

  return (
    <div className="flex h-screen">
      <ToastContainer position="top-right" autoClose={5000} />
      <div className="w-[70%] sm:flex hidden">
        <BackGroundAuth />
      </div>
      <div className="flex flex-col justify-center w-full p-6 mx-auto bg-white shadow-md rounded-md md:flex-row md:p-0 md:bg-transparent md:shadow-none">
        <div className="flex flex-col justify-center w-full p-8 bg-white rounded-md md:rounded-r-md">
          <>
            {step === 0 && (
              <form
                className="w-full"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleLogin();
                }}
              >
                <div className="md:w-2/3 m-auto">
                  <h2 className="mb-8 text-4xl font-extrabold">Login</h2>
                  <div className="mb-4">
                    <p className="font-semibold">Email</p>
                    <InputField
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <p className="font-semibold">Password</p>
                    <div className="relative">
                      <InputField
                        type={passwordVisible ? "text" : "password"}
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="p-2 w-full rounded-2xl mt-1 bg-white border-2"
                      />
                      <button
                        type="button"
                        className="absolute inset-y-0 right-0 top-1/2 transform -translate-y-1/2 mr-4 flex items-center text-sm leading-5"
                        onClick={() => setPasswordVisible(!passwordVisible)}
                      >
                        {passwordVisible ? (
                          <FaRegEyeSlash className="w-5 h-5 text-gray-700" />
                        ) : (
                          <FaEye className="w-5 h-5 text-gray-700" />
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="mb-4">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox text-purpleButton bg-purpleButton"
                      />
                      <span className="ml-2 text-sm">Remember Me</span>
                    </label>
                  </div>
                  <div className="w-full flex justify-between">
                    <button
                      type="button"
                      onClick={() => setStep(1)}
                      className="text-blue-500"
                    >
                      Forgot your Password?
                    </button>
                    <div className="w-24 bg-purpleButton flex justify-around rounded-full items-center py-2 px-4">
                      <button
                        type="submit"
                        className="text-white flex items-center"
                        disabled={loading}
                      >
                        {loading ? <ButtonLoader /> : "Login"}
                        {!loading && (
                          <FaArrowRight className="text-white ml-2" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
            {step === 1 && (
              <div className="w-full">
                <div className="md:w-2/3 m-auto">
                  <h2 className="mb-8 text-4xl font-extrabold">
                    Forgot Password
                  </h2>
                  <div className="mb-4">
                    <p className="font-semibold">Enter your registered email</p>
                    <InputField
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="w-full flex justify-end">
                    <button
                      type="button"
                      onClick={handleSendOtp}
                      className={`bg-purpleButton text-white py-2 px-4 rounded-full ${
                        otpLoading ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                      disabled={otpLoading}
                    >
                      {otpLoading ? <ButtonLoader /> : "Send OTP"}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {step === 2 && (
              <div className="w-full">
                <div className="md:w-2/3 m-auto">
                  <h2 className="mb-8 text-4xl font-extrabold">Verify OTP</h2>
                  <div className="mb-4">
                    <p className="font-semibold">
                      Enter the OTP sent to your email
                    </p>
                    <OTPInput
                      length={5}
                      onChangeOTP={(otpValue) => setOtp(otpValue)}
                    />
                  </div>
                  <div className="w-full flex justify-end">
                    <button
                      type="button"
                      onClick={handleVerifyOtp}
                      className={`bg-purpleButton text-white py-2 px-4 rounded-full ${
                        otpLoading ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                      disabled={otpLoading}
                    >
                      {otpLoading ? <ButtonLoader /> : "Verify OTP"}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {step === 3 && (
              <div className="w-full">
                <div className="md:w-2/3 m-auto">
                  <h2 className="mb-8 text-4xl font-extrabold">
                    Reset Password
                  </h2>
                  <div className="mb-4">
                    <p className="font-semibold">New Password</p>
                    <div className="relative">
                      <InputField
                        type={confirmPasswordVisible ? "text" : "password"}
                        placeholder="New Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className={`p-2 w-full rounded-2xl mt-1 ${
                          confirmPassword ? "bg-slate-100" : "bg-white"
                        } border-2`}
                      />
                      <button
                        type="button"
                        className="absolute inset-y-0 right-0 top-1/2 transform -translate-y-1/2 mr-4 flex items-center text-sm leading-5"
                        onClick={() =>
                          setConfirmPasswordVisible(!confirmPasswordVisible)
                        }
                      >
                        {confirmPasswordVisible ? (
                          <FaRegEyeSlash className="w-5 h-5 text-gray-700" />
                        ) : (
                          <FaEye className="w-5 h-5 text-gray-700" />
                        )}
                      </button>
                    </div>
                  </div>

                  <div className="mb-4">
                    <p className="font-semibold">Confirm New Password</p>
                    <div className="relative">
                      <InputField
                        type={confirmedPasswordVisible ? "text" : "password"}
                        placeholder="Confirm New Password"
                        value={confirmedPassword}
                        onChange={(e) => setConfirmedPassword(e.target.value)}
                        className={`p-2 w-full rounded-2xl mt-1 ${
                          confirmedPassword ? "bg-slate-100" : "bg-white"
                        } border-2`}
                      />
                      <button
                        type="button"
                        className="absolute inset-y-0 right-0 top-1/2 transform -translate-y-1/2 mr-4 flex items-center text-sm leading-5"
                        onClick={() =>
                          setConfirmedPasswordVisible(!confirmedPasswordVisible)
                        }
                      >
                        {confirmedPasswordVisible ? (
                          <FaRegEyeSlash className="w-5 h-5 text-gray-700" />
                        ) : (
                          <FaEye className="w-5 h-5 text-gray-700" />
                        )}
                      </button>
                    </div>
                  </div>

                  <div className="w-full flex justify-end">
                    <button
                      type="button"
                      onClick={handlePasswordChange}
                      className={`bg-purpleButton text-white py-2 px-4 rounded-full ${
                        otpLoading ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                      disabled={otpLoading}
                    >
                      {otpLoading ? <ButtonLoader /> : "Change Password"}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default LoginAdmin;

import axios from "axios";
// Set up your base URL for the API
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Replace with your actual base URL

// Function to create an Axios instance with a bearer token
const createApiClient = () => {
  const token = localStorage.getItem("accessToken"); // Get the latest token
  return axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

// login admin action
export const loginAdmin = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.post("/admin/login/", payload);
    const { accessToken } = response.data.data;

    // Set the token in local storage
    localStorage.setItem("accessToken", accessToken);

    // Ensure the token is set before dispatching and returning the response
    if (localStorage.getItem("accessToken") === accessToken) {
      // console.log("login success ==>", response.data.data);
      dispatch({ type: "STORE_ADMIN_PROFILE", payload: response.data.data });
      return response.data;
    } else {
      throw new Error("Failed to store access token.");
    }
  } catch (error) {
    // console.log("login failed ==>", error.response.data);
    throw error.response ? error.response.data : error.message;
  }
};

// get admin profile
export const getAdminProfile = () => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.get("/admin/");
    dispatch({ type: "STORE_ADMIN_PROFILE", payload: response.data.data });
    console.log("profile get success ==>", response.data);
    return response.data;
  } catch (error) {
    console.log("profile get failed ==>", error.response.data);
    throw error.response ? error.response.data : error.message;
  }
};

// logout
export const logout = () => (dispatch) => {
  dispatch({ type: "LOGOUT" });
  // Additional signout logic if necessary
};

// get employer help and support
export const getEmployerHelpAndSupport = () => async (dispatch) => {
  try {
    // example response
    /*
  {
  "status": "success",
  "data": [
    {
      "_id": "66c21d2d245ef33668346cf9",
      "title": "whats is grapesoft",
      "description": "this is description",
      "video": "https://grapesoftcouk.s3.amazonaws.com/file_1723993830662_SampleVideo_1280x720_5mb.mp4",
      "sort": 1,
      "createdAt": "2024-08-18T16:11:25.744Z",
      "updatedAt": "2024-08-18T16:25:56.681Z",
      "__v": 0
    },
    {
      "_id": "66c21087553bc7381c7ab462",
      "title": "string",
      "description": "string",
      "video": null,
      "sort": 2,
      "createdAt": "2024-08-18T15:17:27.051Z",
      "updatedAt": "2024-08-18T16:25:56.472Z",
      "__v": 0
    }
  ],
  "message": "All help and guide questionnaires"
}
  */
    const apiClient = createApiClient();
    const response = await apiClient.get("/helpguideemployer/");
    dispatch({
      type: "STORE_EMPLOYER_HELP_GUIDE",
      payload: response.data.data,
    });
    console.log("employer help and support get success ==>", response.data);
    return response.data;
  } catch (error) {
    console.log(
      "employer help and support get failed ==>",
      error.response.data
    );
    throw error.response ? error.response.data : error.message;
  }
};

// sort the employer help and support
export const sortEmployerHelpAndSupport = (payload) => async (dispatch) => {
  try {
    console.log(payload);
    const apiClient = createApiClient();
    const response = await apiClient.put(
      `/helpguideemployer/sort/${payload.id}/`,
      { sort: payload.sort }
    );

    dispatch({
      type: "EDIT_SORTED_EMPLOYER_HELP_GUIDE",
      payload: {
        sort: payload.sort,
        id: payload.id,
      },
    });

    console.log("employer help and support sort success ==> ", response.data);
    return response.data;
  } catch (error) {
    console.log("employer help and support sort failed ==> ", error);
    throw error;
  }
};

// delete the help and guide
export const deleteEmployerHelpAndSupport = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.delete(
      `/helpguideemployer/${payload.id}/`
    );
    dispatch({
      type: "DELETE_EMPLOYER_HELP_GUIDE",
      payload: payload.id,
    });

    console.log("employer help and support sort success ==> ", response.data);
    return response.data;
  } catch (error) {
    console.log("employer help and support sort failed ==> ", error);
    throw error;
  }
};

// post the help and guide
export const postEmployerHelpAndSupport = (payload) => async (dispatch) => {
  try {
    // required payload
    /*
    {
  "title": "string",
  "description": "string",
  "video": "string"
  }
    */
    const apiClient = createApiClient();
    const response = await apiClient.post(`/helpguideemployer/`, payload);
    dispatch({
      type: "STORE_EMPLOYER_HELP_GUIDE",
      payload: [response.data.data],
    });
    console.log("employer help and support sort success ==> ", response.data);
    return response.data;
  } catch (error) {
    console.log("employer help and support sort failed ==> ", error);
    throw error;
  }
};

// put the edit help and guide
export const editEmployerHelpAndSupport = (payload) => async (dispatch) => {
  try {
    // required payload
    /*
    {
  "title": "string",
  "description": "string",
  "video": "string"
  }
    */
    const apiClient = createApiClient();
    let editedPayload = payload;
    let _id = payload._id;
    editedPayload._id = undefined;
    editedPayload.createdAt = undefined;
    editedPayload.updatedAt = undefined;
    editedPayload.__v = undefined;
    const response = await apiClient.put(
      `/helpguideemployer/${_id}/`,
      editedPayload
    );
    dispatch({
      type: "EDIT_EMPLOYER_HELP_GUIDE",
      payload: response.data.data,
    });
    // console.log("employer help and support sort success ==> ", response.data);
    return response.data;
  } catch (error) {
    // console.log("employer help and support sort failed ==> ", error);
    throw error;
  }
};
// get candidate help and support
export const getcandidateHelpAndSupport = () => async (dispatch) => {
  try {
    // example response
    /*
  {
  "status": "success",
  "data": [
    {
      "_id": "66c21d2d245ef33668346cf9",
      "title": "whats is grapesoft",
      "description": "this is description",
      "video": "https://grapesoftcouk.s3.amazonaws.com/file_1723993830662_SampleVideo_1280x720_5mb.mp4",
      "sort": 1,
      "createdAt": "2024-08-18T16:11:25.744Z",
      "updatedAt": "2024-08-18T16:25:56.681Z",
      "__v": 0
    },
    {
      "_id": "66c21087553bc7381c7ab462",
      "title": "string",
      "description": "string",
      "video": null,
      "sort": 2,
      "createdAt": "2024-08-18T15:17:27.051Z",
      "updatedAt": "2024-08-18T16:25:56.472Z",
      "__v": 0
    }
  ],
  "message": "All help and guide questionnaires"
}
  */
    const apiClient = createApiClient();
    const response = await apiClient.get("/helpguidecandidate/");
    dispatch({
      type: "STORE_candidate_HELP_GUIDE",
      payload: response.data.data,
    });
    console.log("candidate help and support get success ==>", response.data);
    return response.data;
  } catch (error) {
    console.log(
      "candidate help and support get failed ==>",
      error.response.data
    );
    throw error.response ? error.response.data : error.message;
  }
};

// sort the candidate help and support
export const sortcandidateHelpAndSupport = (payload) => async (dispatch) => {
  try {
    console.log(payload);
    const apiClient = createApiClient();
    const response = await apiClient.put(
      `/helpguidecandidate/sort/${payload.id}/`,
      { sort: payload.sort }
    );

    dispatch({
      type: "EDIT_SORTED_candidate_HELP_GUIDE",
      payload: {
        sort: payload.sort,
        id: payload.id,
      },
    });

    console.log("candidate help and support sort success ==> ", response.data);
    return response.data;
  } catch (error) {
    console.log("candidate help and support sort failed ==> ", error);
    throw error;
  }
};

// delete the help and guide
export const deletecandidateHelpAndSupport = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.delete(
      `/helpguidecandidate/${payload.id}/`
    );
    dispatch({
      type: "DELETE_candidate_HELP_GUIDE",
      payload: payload.id,
    });

    console.log("candidate help and support sort success ==> ", response.data);
    return response.data;
  } catch (error) {
    console.log("candidate help and support sort failed ==> ", error);
    throw error;
  }
};

// post the help and guide
export const postcandidateHelpAndSupport = (payload) => async (dispatch) => {
  try {
    // required payload
    /*
    {
  "title": "string",
  "description": "string",
  "video": "string"
  }
    */
    const apiClient = createApiClient();
    const response = await apiClient.post(`/helpguidecandidate/`, payload);
    dispatch({
      type: "STORE_candidate_HELP_GUIDE",
      payload: [response.data.data],
    });
    console.log("candidate help and support sort success ==> ", response.data);
    return response.data;
  } catch (error) {
    console.log("candidate help and support sort failed ==> ", error);
    throw error;
  }
};

// put the edit help and guide
export const editcandidateHelpAndSupport = (payload) => async (dispatch) => {
  try {
    // required payload
    /*
    {
  "title": "string",
  "description": "string",
  "video": "string"
  }
    */
    const apiClient = createApiClient();
    let editedPayload = payload;
    let _id = payload._id;
    editedPayload._id = undefined;
    editedPayload.createdAt = undefined;
    editedPayload.updatedAt = undefined;
    editedPayload.__v = undefined;
    const response = await apiClient.put(
      `/helpguidecandidate/${_id}/`,
      editedPayload
    );
    dispatch({
      type: "EDIT_candidate_HELP_GUIDE",
      payload: response.data.data,
    });
    // console.log("candidate help and support sort success ==> ", response.data);
    return response.data;
  } catch (error) {
    // console.log("candidate help and support sort failed ==> ", error);
    throw error;
  }
};

// upload file
export const uploadFiles = async (files) => {
  // sample response
  //{
  //   "status": "success",
  //   "photos": [
  //     "file_1723482238791_SampleVideo_1280x720_5mb.mp4"
  //   ]
  // }
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append("file", files[i], files[i].name); // Use "file" as the key
  }

  // Log the FormData contents
  for (let pair of formData.entries()) {
    console.log(pair[0] + ", " + pair[1]);
  }

  try {
    const apiClient = createApiClient();
    const response = await apiClient.post("/s3/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    // console.log("file response>>>>", response.data);
    return response.data;
  } catch (error) {
    // console.error("Upload error:", error);
    throw error.response.data;
  }
};

// get all packages
export const getAllPackages = (payload) => async (dispatch) => {
  try {
    // example response
    /*
    {
  "status": "success",
  "data": [
    {
      "_id": "66c318de8782f577bd26cab9",
      "title": "Free Package",
      "features": [
        "Feature 1",
        "Feature 2"
      ],
      "pricePerCredit": null,
      "numberOfCredits": 100,
      "type": 0,
      "active": true,
      "createdAt": "2024-08-19T10:05:18.203Z",
      "updatedAt": "2024-08-19T10:47:25.061Z",
      "__v": 0
    },
    {
      "_id": "66c322925cc3ae68c9f70292",
      "title": "Premium Package",
      "features": [
        "Feature 1",
        "Feature 2"
      ],
      "pricePerCredit": 10,
      "numberOfCredits": 100,
      "type": 1,
      "active": true,
      "createdAt": "2024-08-19T10:46:42.351Z",
      "updatedAt": "2024-08-19T10:46:42.351Z",
      "__v": 0
    }
  ],
  "message": "Packages retrieved"
}
    */
    const apiClient = createApiClient();
    const response = await apiClient.get("/package/");
    console.log("packages response>>>>", response.data);

    dispatch({
      type: "ADD_PACKAGE",
      payload: response.data.data,
    });
    return response.data.data;
  } catch (error) {
    console.log("get all packages failed ==> ", error);
    throw error;
  }
};

// create  package
export const createPackage = (payload) => async (dispatch) => {
  try {
    // example payload
    /*
   {
  "title": "Premium Package",
  "features": [
    "Feature 1",
    "Feature 2"
  ],
  "pricePerCredit": 10,
  "numberOfCredits": 100
}
    */
    const apiClient = createApiClient();
    const response = await apiClient.post("/package/", payload);
    console.log("packages response>>>>", response.data);

    dispatch({
      type: "ADD_PACKAGE",
      payload: [response.data.data],
    });
    return response.data.data;
  } catch (error) {
    console.log("get all packages failed ==> ", error);
    throw error;
  }
};

// update package
export const updatePackage = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const id = payload._id;
    const payloadForEdit = payload;
    payloadForEdit._id = undefined;
    payloadForEdit.type = undefined;
    payloadForEdit.updatedAt = undefined;
    payloadForEdit.createdAt = undefined;
    payloadForEdit.__v = undefined;
    const response = await apiClient.put(`/package/${id}`, payloadForEdit);
    // console.log("packages response>>>>", response.data);
    dispatch({
      type: "EDIT_PACKAGE",
      payload: response.data.data,
    });
    return response.data;
  } catch (error) {
    // console.log("get all packages failed ==> ", error);
    throw error;
  }
};

// remove package
export const removePackage = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.delete(`/package/${payload}`);
    // console.log("packages response>>>>", response.data);
    dispatch({
      type: "DELETE_PACKAGE",
      payload,
    });
    return response.data.data;
  } catch (error) {
    // console.log("get all packages failed ==> ", error);
    throw error;
  }
};

// get test Permissions
export const getTestPermissions = (payload) => async (dispatch) => {
  try {
    // example response
    /*
{
  "status": "success",
  "data": {
    "_id": "66cb0ca1ad1a5491332e846c",
    "__v": 0,
    "createdAt": "2024-08-25T10:51:09.926Z",
    "fileDataMax": 10,
    "multiChoiceQuestions": 4,
    "openQuestionWords": 200,
    "updatedAt": "2024-08-25T11:18:31.493Z"
  },
  "message": "Test permissions get successfully"
}
    */
    const apiClient = createApiClient();
    const response = await apiClient.get("/testPermissions/");
    console.log("testPermissions response>>>>", response.data);

    dispatch({
      type: "STORE_TEST_PERMISSIONS",
      payload: response.data.data,
    });
    return response.data.data;
  } catch (error) {
    console.log("failed testPermissions ==> ", error);
    throw error;
  }
};

// edit all test Permissions
export const editTestPermissions = (payload) => async (dispatch) => {
  try {
    // example payload
    /*
{
  "multiChoiceQuestions": 4,
  "openQuestionWords": 200,
  "fileDataMax": 10
}
    */
    const apiClient = createApiClient();
    const response = await apiClient.put("/testPermissions/", payload);
    console.log(" edit testPermissions response>>>>", response.data);

    dispatch({
      type: "STORE_TEST_PERMISSIONS",
      payload: response.data.data,
    });
    return response.data.data;
  } catch (error) {
    console.log("failed edit testPermissions ==> ", error);
    throw error;
  }
};

// get statistics
export const getStatistics = (payload) => async (dispatch) => {
  try {
    // example response
    /*
{
  "status": "success",
  "data": {
    "totalCreditsPurchased": 89,
    "totalSubscriptionCost": 267,
    "employers": 3,
    "candidates": 3,
    "activeJobs": 3,
    "totalJobQuantities": 3
  },
  "message": "statistics fetched"
}
    */
    const apiClient = createApiClient();
    const response = await apiClient.get("/admin/statistics/");
    // console.log(" statistics response>>>>", response.data);

    return response.data.data;
  } catch (error) {
    // console.log("failed get statistics ==> ", error);
    throw error;
  }
};

// get jobApplicationRecentActivity
export const jobApplicationRecentActivity = (payload) => async (dispatch) => {
  try {
    // example response
    /*
{
  "status": "success",
  "data": {
    "jobApplications": [
      {
        "_id": "66d479077364ac50c2dbe6fb",
        "employerId": "66b202ea912827180a73fa01",
        "candidateId": "66d0aa4d5e8a5d7b58acf848",
        "jobId": "66d342e78b998d6b7937fd7d",
        "first_name": "mantiq",
        "last_name": "mantiq soft",
        "email": "mantiqsoft@gmail.com",
        "countryOfRecidence": "Algeria",
        "countryOfBirth": "Australia",
        "timezone": null,
        "contactNumber": "2345678975643",
        "availabilityDate": "2024-09-18T00:00:00.000Z",
        "aboutVideo": "file_1724951087952_SampleVideo_1280x720_5mb.mp4",
        "cv": "file_1724951092933_HR interview application.pdf",
        "coverLetter": "drtyu dyu d56ui",
        "status": 5,
        "success": 1,
        "note": "nice application",
        "isDeleted": false,
        "createdAt": "2024-09-01T14:24:07.543Z",
        "updatedAt": "2024-09-01T14:36:25.356Z",
        "__v": 0,
        "candidate": {
          "_id": "66d0aa4d5e8a5d7b58acf848",
          "avatar": "file_1725118007515_man2.jfif",
          "first_name": "mantiq",
          "last_name": "mantiq soft",
          "email": "mantiqsoft@gmail.com",
          "password": "U2FsdGVkX1+jnKaqTEgy+ciVqi211dU0DZ5dK50NLDA=",
          "encryptOTP": null,
          "countryOfRecidence": "Algeria",
          "countryOfBirth": "Australia",
          "timezone": "MST",
          "contactNumber": "2345678975643",
          "whatsapp": null,
          "telegram": null,
          "skype": null,
          "other": null,
          "aboutVideo": "file_1724951087952_SampleVideo_1280x720_5mb.mp4",
          "cv": "file_1724951092933_HR interview application.pdf",
          "coverLetter": "drtyu dyu d56ui",
          "refreshToken": [],
          "isverified": true,
          "createdAt": "2024-08-29T17:05:17.964Z",
          "updatedAt": "2024-08-31T15:26:54.035Z",
          "__v": 0
        }
      },
      {
        "_id": "66d45d9d0c5053c9918995df",
        "employerId": "66b202ea912827180a73fa01",
        "candidateId": "66d0aa4d5e8a5d7b58acf848",
        "jobId": "66d342e78b998d6b7937fd7d",
        "first_name": "mantiq",
        "last_name": "mantiq soft",
        "email": "mantiqsoft@gmail.com",
        "countryOfRecidence": "Algeria",
        "countryOfBirth": "Australia",
        "timezone": null,
        "contactNumber": "2345678975643",
        "availabilityDate": "2024-09-09T00:00:00.000Z",
        "aboutVideo": "file_1724951087952_SampleVideo_1280x720_5mb.mp4",
        "cv": "file_1724951092933_HR interview application.pdf",
        "coverLetter": "drtyu dyu d56ui",
        "status": 0,
        "success": 0,
        "note": null,
        "isDeleted": true,
        "createdAt": "2024-09-01T12:27:09.078Z",
        "updatedAt": "2024-09-01T14:20:43.472Z",
        "__v": 0,
        "candidate": {
          "_id": "66d0aa4d5e8a5d7b58acf848",
          "avatar": "file_1725118007515_man2.jfif",
          "first_name": "mantiq",
          "last_name": "mantiq soft",
          "email": "mantiqsoft@gmail.com",
          "password": "U2FsdGVkX1+jnKaqTEgy+ciVqi211dU0DZ5dK50NLDA=",
          "encryptOTP": null,
          "countryOfRecidence": "Algeria",
          "countryOfBirth": "Australia",
          "timezone": "MST",
          "contactNumber": "2345678975643",
          "whatsapp": null,
          "telegram": null,
          "skype": null,
          "other": null,
          "aboutVideo": "file_1724951087952_SampleVideo_1280x720_5mb.mp4",
          "cv": "file_1724951092933_HR interview application.pdf",
          "coverLetter": "drtyu dyu d56ui",
          "refreshToken": [],
          "isverified": true,
          "createdAt": "2024-08-29T17:05:17.964Z",
          "updatedAt": "2024-08-31T15:26:54.035Z",
          "__v": 0
        }
      },
      {
        "_id": "66d0bb605e8a5d7b58acfa84",
        "candidateId": "66d0aa4d5e8a5d7b58acf848",
        "jobId": "66cf632cf942e48daf22e788",
        "first_name": "mantiq",
        "last_name": "mantiq soft",
        "email": "mantiqsoft@gmail.com",
        "countryOfRecidence": "Algeria",
        "countryOfBirth": "Australia",
        "timezone": null,
        "contactNumber": "2345678975643",
        "availabilityDate": "2024-08-20T00:00:00.000Z",
        "aboutVideo": "file_1724951087952_SampleVideo_1280x720_5mb.mp4",
        "cv": "file_1724951092933_HR interview application.pdf",
        "coverLetter": "drtyu dyu d56ui",
        "status": 5,
        "success": 1,
        "note": "ok",
        "createdAt": "2024-08-29T18:18:08.557Z",
        "updatedAt": "2024-08-31T20:46:15.493Z",
        "__v": 0,
        "employerId": "66b202ea912827180a73fa01",
        "isDeleted": true,
        "candidate": {
          "_id": "66d0aa4d5e8a5d7b58acf848",
          "avatar": "file_1725118007515_man2.jfif",
          "first_name": "mantiq",
          "last_name": "mantiq soft",
          "email": "mantiqsoft@gmail.com",
          "password": "U2FsdGVkX1+jnKaqTEgy+ciVqi211dU0DZ5dK50NLDA=",
          "encryptOTP": null,
          "countryOfRecidence": "Algeria",
          "countryOfBirth": "Australia",
          "timezone": "MST",
          "contactNumber": "2345678975643",
          "whatsapp": null,
          "telegram": null,
          "skype": null,
          "other": null,
          "aboutVideo": "file_1724951087952_SampleVideo_1280x720_5mb.mp4",
          "cv": "file_1724951092933_HR interview application.pdf",
          "coverLetter": "drtyu dyu d56ui",
          "refreshToken": [],
          "isverified": true,
          "createdAt": "2024-08-29T17:05:17.964Z",
          "updatedAt": "2024-08-31T15:26:54.035Z",
          "__v": 0
        }
      }
    ],
    "pagination": {
      "currentPage": 1,
      "totalPages": 1,
      "totalItems": 3,
      "itemsPerPage": 10
    }
  },
  "message": "Recent activity fetched"
}
    */
    const apiClient = createApiClient();
    const response = await apiClient.get(
      `/jobApplication/recentActivity?page=${payload.page}&limit=${payload.limit}`
    );
    // console.log(" jobApplicationRecentActivity response>>>>", response.data);

    return response.data.data;
  } catch (error) {
    // console.log("failed get jobApplicationRecentActivity ==> ", error);
    throw error;
  }
};

// get toggleBlock
export const toggleBlockUnBloack = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.put(
      `/candidate/toggleBlock?id=${payload.id}`
    );
    // console.log(" toggleBlockUnBloack response>>>>", response.data);

    return response.data.data;
  } catch (error) {
    // console.log("failed get toggleBlockUnBloack ==> ", error);
    throw error;
  }
};
// get softDeleteCandidate
export const softDeleteCandidate = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.delete(`/candidate/?id=${payload.id}`);
    console.log(" softDeleteCandidate response>>>>", response.data);

    return response.data.data;
  } catch (error) {
    console.log("failed get softDeleteCandidate ==> ", error);
    throw error;
  }
};
// put updateEmailCandidate
export const updateEmailCandidate = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    // required payload
    /*
{
  "candidateId": "string",
  "newEmail": "user@example.com"
}
    */
    const response = await apiClient.put(`/candidate/update-email/`, payload);
    console.log(" updateEmailCandidate response>>>>", response.data);

    return response.data.data;
  } catch (error) {
    console.log("failed get updateEmailCandidate ==> ", error);
    throw error;
  }
};
// get candidatesAdmin
export const candidatesAdmin = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    // example response
    /*
{
  "status": "success",
  "data": {
    "candidates": [
      {
        "_id": "66d0aa4d5e8a5d7b58acf848",
        "avatar": "https://grapesoftcouk.s3.amazonaws.com/file_1725118007515_man2.jfif",
        "first_name": "mantiq",
        "last_name": "mantiq soft",
        "email": "mantiqsoft@gmail.com",
        "encryptOTP": null,
        "countryOfRecidence": "Algeria",
        "countryOfBirth": "Australia",
        "timezone": "MST",
        "contactNumber": "2345678975643",
        "whatsapp": null,
        "telegram": null,
        "skype": null,
        "other": null,
        "aboutVideo": "file_1724951087952_SampleVideo_1280x720_5mb.mp4",
        "cv": "file_1724951092933_HR interview application.pdf",
        "coverLetter": "drtyu dyu d56ui",
        "isverified": true,
        "createdAt": "2024-08-29T17:05:17.964Z",
        "updatedAt": "2024-09-04T18:48:13.021Z",
        "__v": 0,
        "isBlocked": false,
        "isDeleted": false
      },
      {
        "isverified": false,
        "isDeleted": false,
        "isBlocked": false,
        "_id": "66ca4a03bcada1a01ec7c4a0",
        "avatar": null,
        "first_name": "mantiq",
        "last_name": "Waheed",
        "email": "abdulwaheedowner@gmail.com",
        "encryptOTP": null,
        "countryOfRecidence": "Bahrain",
        "countryOfBirth": "Barbados",
        "timezone": null,
        "contactNumber": "2345678975643",
        "whatsapp": null,
        "telegram": null,
        "skype": null,
        "other": null,
        "aboutVideo": "file_1724533222020_employer area screen record.mp4",
        "cv": "file_1724533225904_HR interview application.pdf",
        "coverLetter": "vhn",
        "createdAt": "2024-08-24T21:00:51.319Z",
        "updatedAt": "2024-08-24T21:00:51.319Z",
        "__v": 0
      },
      {
        "isverified": false,
        "isDeleted": false,
        "isBlocked": false,
        "_id": "66c77ac585b7d3341d8818da",
        "avatar": "https://grapesoftcouk.s3.amazonaws.com/file_1724602426102_avatar.jpg",
        "first_name": "Abdul Wadood",
        "last_name": "Mughal",
        "email": "abdulwadoodowner@gmail.com",
        "encryptOTP": "U2FsdGVkX1+hEgd2UXXPV8xBZ3NINd6AXjtkYAX5ITjHtIGzs4dpBRLox8wDZ2bb94FXE7P244pUhC+SPF/RzOD5DOvUVtOBE3jRctUJfo0=",
        "countryOfRecidence": "Australia",
        "countryOfBirth": "Antigua and Barbuda",
        "timezone": "MST",
        "contactNumber": "string",
        "whatsapp": "ok",
        "telegram": null,
        "skype": " ytuyioi;o",
        "other": null,
        "aboutVideo": "file_1724344476936_SampleVideo_1280x720_5mb.mp4",
        "cv": "file_1724344393770_HR interview application.pdf",
        "coverLetter": "string dtui fuoi",
        "createdAt": "2024-08-22T17:52:05.138Z",
        "updatedAt": "2024-08-25T16:13:59.443Z",
        "__v": 0
      }
    ],
    "pagination": {
      "totalDocuments": 3,
      "totalPages": 1,
      "currentPage": 1,
      "pageSize": 10
    }
  },
  "message": "Candidates fetched successfully"
}
    */
    const response = await apiClient.get(
      `/candidate/admin?page=${payload.page}&limit=${payload.limit}&filter=${payload.filter}`
    );
    console.log(" candidatesAdmin response>>>>", response.data);

    return response.data.data;
  } catch (error) {
    console.log("failed get candidatesAdmin ==> ", error);
    throw error;
  }
};
// get employerAdmin
export const employerAdmin = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    // example response
    /*
{
  "status": "success",
  "data": {
    "employers": [
      {
        "_id": "66be60ee710c206a4235be25",
        "avatar": null,
        "first_name": "mantiq",
        "last_name": "soft",
        "company_name": "software",
        "email": "mantiqsoft@gmail.com",
        "active": true,
        "NewApplication": true,
        "TestTaken": true,
        "ContractSigned": true,
        "createdAt": "2024-08-15T20:11:26.193Z",
        "updatedAt": "2024-08-15T20:11:26.193Z",
        "__v": 0,
        "currentSubscription": null
      },
      {
        "_id": "66b24ae052663ec3dba558e0",
        "avatar": null,
        "first_name": "abdul",
        "last_name": "waheed",
        "company_name": "leader name",
        "email": "abdulwaheedowner@gmail.com",
        "active": true,
        "NewApplication": true,
        "TestTaken": true,
        "ContractSigned": true,
        "createdAt": "2024-08-06T16:10:08.488Z",
        "updatedAt": "2024-08-06T16:10:08.488Z",
        "__v": 0,
        "currentSubscription": null
      },
      {
        "_id": "66b202ea912827180a73fa01",
        "avatar": "https://grapesoftcouk.s3.amazonaws.com/file_1723752529796_man2.jfif",
        "first_name": "Abdul Wadood",
        "last_name": "Mughal",
        "company_name": "Your Company",
        "email": "abdulwadoodowner@gmail.com",
        "active": true,
        "NewApplication": true,
        "TestTaken": true,
        "ContractSigned": false,
        "createdAt": "2024-08-06T11:03:06.021Z",
        "updatedAt": "2024-08-25T16:09:28.877Z",
        "__v": 0,
        "currentSubscription": {
          "currentPackage": {
            "packageStatus": {
              "numberOfCreditsAdminCustomAdded": 0,
              "numberOfCreditsAdminCustomRemove": 0,
              "title": "new is",
              "features": [
                "great package"
              ],
              "pricePerCredit": 3,
              "numberOfCredits": 84,
              "type": 1,
              "active": true
            },
            "transactionId": null,
            "title": "new is",
            "features": [
              "great package"
            ],
            "pricePerCredit": 3,
            "numberOfCredits": 89,
            "type": 1,
            "active": true
          },
          "_id": "66ce46face8e3bdbfae47912",
          "employerId": "66b202ea912827180a73fa01",
          "subscriptionHistory": [],
          "createdAt": "2024-08-27T21:36:58.145Z",
          "updatedAt": "2024-09-01T14:27:42.917Z",
          "__v": 0
        }
      }
    ],
    "pagination": {
      "totalDocuments": 3,
      "totalPages": 1,
      "currentPage": 1,
      "pageSize": 10
    }
  },
  "message": "Employers fetched successfully"
}
    */
    const response = await apiClient.get(
      `/employer/admin?page=${payload.page}&limit=${payload.limit}&filter=${payload.filter}`
    );
    console.log(" employerAdmin response>>>>", response.data);

    return response.data.data;
  } catch (error) {
    console.log("failed get employerAdmin ==> ", error);
    throw error;
  }
};

// get toggleBlock
export const toggleBlockUnBloackEmployer = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.put(
      `/employer/toggle-status?employerId=${payload.id}`
    );
    // console.log(" toggleBlockUnBloack response>>>>", response.data);

    return response.data.data;
  } catch (error) {
    // console.log("failed get toggleBlockUnBloack ==> ", error);
    throw error;
  }
};
// get softDeleteCandidate
export const softDeleteEmployer = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.delete(
      `/employer/delete/?employerId=${payload.id}`
    );
    console.log(" softDeleteCandidate response>>>>", response.data);

    return response.data.data;
  } catch (error) {
    console.log("failed get softDeleteCandidate ==> ", error);
    throw error;
  }
};

// put updateEmployer
export const updateEmployer = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    // required payload
    /*
{
  "employerId": "string",
  "newEmail": "user@example.com",
  "credits": 0
}
    */
    const response = await apiClient.put(`/employer/update/`, payload);
    console.log(" updateEmployer response>>>>", response.data);

    return response.data.data;
  } catch (error) {
    console.log("failed get updateEmployer ==> ", error);
    throw error;
  }
};

// get subscription history
export const getSubscription = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    // sample response
    /*
{
  "status": "success",
  "data": {
    "currentPackage": {
      "packageStatus": {
        "numberOfCreditsAdminCustomAdded": 0,
        "numberOfCreditsAdminCustomRemove": 0,
        "title": "576t8",
        "features": [
          "rse"
        ],
        "pricePerCredit": 23,
        "numberOfCredits": 345,
        "type": 1,
        "active": true
      },
      "transactionId": null,
      "title": "576t8",
      "features": [
        "rse"
      ],
      "pricePerCredit": 23,
      "numberOfCredits": 345,
      "type": 1,
      "active": true
    },
    "_id": "66ce46face8e3bdbfae47912",
    "employerId": "66b202ea912827180a73fa01",
    "subscriptionHistory": [
      {
        "packageStatus": {
          "title": "new is",
          "features": [
            "great package"
          ],
          "pricePerCredit": 3,
          "numberOfCredits": 80,
          "numberOfCreditsAdminCustomAdded": 0,
          "numberOfCreditsAdminCustomRemove": 4,
          "type": 1,
          "active": true,
          "transactionId": null
        },
        "transactionId": null,
        "title": "new is",
        "features": [
          "great package"
        ],
        "pricePerCredit": 3,
        "numberOfCredits": 89,
        "type": 1,
        "active": true,
        "_id": "66db34c4f7c41642a5290e3e"
      },
      {
        "packageStatus": {
          "title": "tyu",
          "features": [
            "tfgh"
          ],
          "pricePerCredit": 334,
          "numberOfCredits": 56,
          "numberOfCreditsAdminCustomAdded": 0,
          "numberOfCreditsAdminCustomRemove": 0,
          "type": 1,
          "active": true,
          "transactionId": null
        },
        "transactionId": null,
        "title": "tyu",
        "features": [
          "tfgh"
        ],
        "pricePerCredit": 334,
        "numberOfCredits": 56,
        "type": 1,
        "active": true,
        "_id": "66db34f7965cd8541b4e724b"
      }
    ],
    "createdAt": "2024-08-27T21:36:58.145Z",
    "updatedAt": "2024-09-06T16:59:35.319Z",
    "__v": 2
  },
  "message": "Subscription fetched successfully"
}
    */
    const response = await apiClient.get(
      `/subscription/admin?employerId=${payload.id}`,
      payload
    );
    console.log(" getSubscription response>>>>", response.data);

    return response.data.data;
  } catch (error) {
    console.log("failed get getSubscription ==> ", error);
    throw error;
  }
};

// send forget password otp
export const sendForgetPassword = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    // sample response
    /*
{
  "email": "admin@example.com"
}
    */
    const response = await apiClient.post(`/admin/sendForgetOTP`, payload);
    console.log(" sendForgetPassword response>>>>", response.data);

    return response.data.data;
  } catch (error) {
    console.log("failed  sendForgetPassword ==> ", error);
    throw error;
  }
};
// verify otp
export const verifyOtp = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    // sample response
    /*
{
  "otp": 123456,
  "email": "example@example.com"
}
    */
    const response = await apiClient.post(`/admin/verifyOTP`, payload);
    console.log(" verifyOtp response>>>>", response.data);

    return response.data.data;
  } catch (error) {
    console.log("failed  verifyOtp ==> ", error);
    throw error;
  }
};
// reset password
export const resetPassword = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    // sample response
    /*
{
  "email": "admin@example.com",
  "otp": "123456",
  "password": "NewStrongPassword123!"
}
    */
    const response = await apiClient.post(`/admin/resetPassword`, payload);
    console.log(" resetPassword response>>>>", response.data);

    return response.data.data;
  } catch (error) {
    console.log("failed  resetPassword ==> ", error);
    throw error;
  }
};

// Fetch Stripe keys
export const getStripeKeys = async () => {
  const apiClient = createApiClient();
  try {
    const response = await apiClient.get("/stripe-key");
    return response.data.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

// Create or update Stripe keys
export const createOrUpdateStripeKeys = async (payload) => {
  const apiClient = createApiClient();
  try {
    const response = await apiClient.post("/stripe-key", payload);
    return response.data.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

import { combineReducers } from "redux";
import authReducer_admin from "./admin/authReducer_admin";
import employerHelpAndGuidanceReducer_admin from "./admin/employerHelpAndGuidanceReducer_admin";
import packagesReducer_admin from "./admin/packagesReducer_admin";
import testPermissionsReducer_admin from "./admin/testPermissionsReducer_admin";
import candidateHelpAndGuidanceReducer_admin from "./admin/candidateHelpAndGuidanceReducer_admin";
// import other reducers here if any

const appReducer = combineReducers({
  authReducer_admin: authReducer_admin,
  employerHelpAndGuidanceReducer_admin: employerHelpAndGuidanceReducer_admin,
  candidateHelpAndGuidanceReducer_admin: candidateHelpAndGuidanceReducer_admin,
  packagesReducer_admin,
  testPermissionsReducer_admin,
  // add other reducers here
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;

import React from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import ButtonLoader from "../Loaders/ButtonLoader";

const Package = ({
  title,
  price,
  features,
  maxCredits,
  freeCredits,
  freeCreditsDuration,
  index,
  deletePackage,
  editPackage,
  active,
  togglePackageActive,
  isLoading,
  currentAction,
  packageId,
}) => {
  const isEditLoading = isLoading && currentAction === `edit-${packageId}`;
  const isDeleteLoading = isLoading && currentAction === `delete-${packageId}`;
  const isToggleLoading = isLoading && currentAction === `toggle-${index}`;

  return (
    <div className="bg-white shadow-md rounded-lg m-4 min-h-56">
      <div className="flex justify-between">
        <h3 className="font-bold mb-4 bg-purpleButton text-white w-[65%] px-4 py-6 rounded-br-3xl text-wrap">
          {title}
        </h3>
        <div className="p-2 flex space-x-2">
          <div
            className="p-1 w-7 h-7 bg-[#f2f3f5] cursor-pointer"
            onClick={editPackage}
          >
            {isEditLoading ? (
              <ButtonLoader />
            ) : (
              <MdEdit className="text-xl text-[#a1a5b7]" />
            )}
          </div>
          {price !== 0 && (
            <div
              className="p-1 w-7 h-7 bg-[#f2f3f5] cursor-pointer"
              onClick={deletePackage}
            >
              {isDeleteLoading ? (
                <ButtonLoader />
              ) : (
                <MdDelete className="text-xl text-[#a1a5b7]" />
              )}
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-row justify-center items-center mb-4">
        {price === 0 ? (
          <div className="text-purpleButton font-semibold text-2xl">Free</div>
        ) : (
          <>
            <div className="text-purpleButton font-bold text-xl">${price}</div>
            <div>/</div>
            <div className="text-purpleButton font-normal">per credit</div>
          </>
        )}
      </div>
      <ul className="mb-4 flex flex-col gap-2 text-[12px] text-center">
        <li className="bg-[#f2f3f5]">Number of Credits: {maxCredits}</li>
        {freeCredits && (
          <li className="bg-[#f2f3f5]">Free Credits: {freeCredits}</li>
        )}
        {freeCreditsDuration && (
          <li className="bg-[#f2f3f5]">
            Free Credits Duration: {freeCreditsDuration} days
          </li>
        )}
        {features.map((feature, index) => (
          <li key={index} className="bg-[#f2f3f5]">
            {feature}
          </li>
        ))}
      </ul>
      {price === 0 && (
        <div className="flex justify-center items-center mb-4">
          <label className="mr-2">Active</label>
          <input
            type="checkbox"
            checked={active}
            onChange={togglePackageActive}
            disabled={isToggleLoading}
          />
          {isToggleLoading && <ButtonLoader />}
        </div>
      )}
    </div>
  );
};

export default Package;

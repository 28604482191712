import React, { useState, useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
// images
import AppliationLogo from "../../src/images/Logo/applicationLogo.png";
import { FaArrowRight } from "react-icons/fa6";
// import SettingsIcon from "../../images/profile/SettingsIcon.png";
import { HiMenu } from "react-icons/hi";
import applicationLogo from "../../src/images/Logo/applicationLogo.png";
import { useDispatch } from "react-redux";

// import component 👇
import Drawer from "react-modern-drawer";

//import styles 👇
import "react-modern-drawer/dist/index.css";
import { getAdminProfile, logout } from "../services/apiService";

function AdminIndex() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        await dispatch(getAdminProfile());
      } catch (error) {
        navigate("/");
      }
    };
    fetchProfile();
  }, [dispatch, navigate]);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const signOut = async () => {
    dispatch(logout());
    await localStorage.removeItem("accessToken");
    navigate("/");
  };

  return (
    <>
      <div className="flex flex-col md:flex-row min-h-screen bg-[#f9fafb]">
        {/* at desktop screen */}
        <div className=" w-[25%]">
          <div className="h-[calc(100vh-100px)] fixed  m-5 py-2 lg:px-7 flex-col gap-20 rounded-2xl bg-[#f2f3f5] hidden md:flex">
            <img src={AppliationLogo} alt="logo" className="w-16" />
            <div className="flex flex-col gap-2 text-[#8e96a7]">
              <Link
                to="/index"
                className={`flex gap-2 items-center ${
                  location.pathname === "/index" ? "font-bold" : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>Statistics</div>
              </Link>
              <Link
                to="/index/candidates"
                className={`flex gap-2 items-center ${
                  location.pathname === "/index/candidates" ? "font-bold" : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>Candidates</div>
              </Link>
              <Link
                to="/index/employers"
                className={`flex gap-2 items-center ${
                  location.pathname === "/index/employers" ? "font-bold" : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>Employer</div>
              </Link>
              <Link
                to="/index/subscriptions"
                className={`flex gap-2 items-center ${
                  location.pathname === "/index/subscriptions"
                    ? "font-bold"
                    : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>Subscriptions</div>
              </Link>
              <Link
                to="/index/helpandguidancecandidate"
                className={`flex gap-2 items-center ${
                  location.pathname === "/index/helpandguidancecandidate"
                    ? "font-bold"
                    : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>Help & Guidance Candidate</div>
              </Link>
              <Link
                to="/index/helpandguidanceemployer"
                className={`flex gap-2 items-center ${
                  location.pathname === "/index/helpandguidanceemployer"
                    ? "font-bold"
                    : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>Help & Guidance Employer</div>
              </Link>
              <Link
                to="/index/payments"
                className={`flex gap-2 items-center ${
                  location.pathname === "/index/payments" ? "font-bold" : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>Payment</div>
              </Link>
              <Link
                to="/index/testPermissions"
                className={`flex gap-2 items-center ${
                  location.pathname === "/index/testPermissions"
                    ? "font-bold"
                    : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>Test Permissions</div>
              </Link>
            </div>
            <div
              className="p-2 bg-purpleButton rounded-lg w-20 text-center text-white cursor-pointer"
              onClick={signOut}
            >
              SignOut
            </div>
          </div>
        </div>
        {/* at mobile screen */}
        <div className="md:hidden flex flex-col gap-20  p-4">
          <div className="flex flex-row justify-between gap-2  items-center">
            <div className="flex items-center gap-2">
              <button onClick={toggleDrawer}>
                <HiMenu />
              </button>
              <img src={applicationLogo} alt="logo" className="w-4 h-4 " />
            </div>
          </div>
          <Drawer
            open={isOpen}
            onClose={toggleDrawer}
            size={200}
            direction="left"
            className="flex flex-col gap-20 py-3 px-3"
            style={{
              height: "calc(100vh - 100px)",
            }}
          >
            {/* <div className="flex flex-col justify-between"> */}
            <div className="flex items-center gap-2">
              <button onClick={toggleDrawer}>
                <HiMenu />
              </button>
              <img src={applicationLogo} alt="logo" className="w-4 h-4 " />
            </div>
            <div className="flex flex-col gap-2 text-[#8e96a7]">
              <Link
                to="/index"
                className={`flex gap-2 items-center ${
                  location.pathname === "/index" ? "font-bold" : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>Statistics</div>
              </Link>
              <Link
                to="/index/candidates"
                className={`flex gap-2 items-center ${
                  location.pathname === "/index/candidates" ? "font-bold" : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>Candidates</div>
              </Link>
              <Link
                to="/index/employers"
                className={`flex gap-2 items-center ${
                  location.pathname === "/index/employers" ? "font-bold" : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>Employers</div>
              </Link>
              <Link
                to="/index/subscriptions"
                className={`flex gap-2 items-center ${
                  location.pathname === "/index/subscriptions"
                    ? "font-bold"
                    : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>Subscriptions</div>
              </Link>
              <Link
                to="/index/helpandguidancecandidate"
                className={`flex gap-2 items-center ${
                  location.pathname === "/index/helpandguidancecandidate"
                    ? "font-bold"
                    : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>Help & Guidance Candidate</div>
              </Link>
              <Link
                to="/index/helpandguidanceemployer"
                className={`flex gap-2 items-center ${
                  location.pathname === "/index/helpandguidanceemployer"
                    ? "font-bold"
                    : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>Help & Guidance Employer</div>
              </Link>
              <Link
                to="/index/payments"
                className={`flex gap-2 items-center ${
                  location.pathname === "/index/payments" ? "font-bold" : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>Payment</div>
              </Link>
              <Link
                to="/index/testPermissions"
                className={`flex gap-2 items-center ${
                  location.pathname === "index/testPermissions"
                    ? "font-bold"
                    : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>Test Permissions</div>
              </Link>
            </div>
            <div
              className="p-2 bg-purpleButton rounded-lg w-20 text-center text-white cursor-pointer"
              onClick={() => {
                navigate("/");
              }}
            >
              SignOut
            </div>
          </Drawer>
        </div>

        <div className="flex flex-col w-full md:w-[70%] md:mt-10">
          <div className=" p-4 flex justify-between items-center">
            <div>
              <h1 className="text-xl font-semibold">Hello, Admin</h1>
              <p className="md:ml-2 text-slate-500">Good Morning</p>
            </div>
          </div>
          <div className="p-4">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminIndex;

import React from "react";
import loginBackground from "../../images/auth/loginBackground.jpg";

function BackgroundAuth() {
  return (
    <div className="relative w-full h-full sm:flex flex-col justify-center items-center">
      <div
        className="absolute inset-0 bg-cover bg-center z-[-1] w-full h-full"
        style={{ backgroundImage: `url(${loginBackground})` }}
      ></div>
      <div className="bg-[#6244fe] bg-opacity-75 w-full h-full flex flex-col justify-center items-start text-white p-4">
        <h1 className="mb-4 text-[60px] font-semibold leading-10">Welcome</h1>
        <p className="text-sm">
          Welcome to Reddot's admin panel, your hub for seamless management of
          candidates, employers, and hiring posts. Whether you're recruiting top
          talent or connecting with prospective employers, our intuitive
          interface empowers you to streamline every step of the process. From
          posting job opportunities to tracking applications, Reddot ensures
          efficiency and clarity at every turn. Dive into detailed candidate
          profiles, manage employer interactions, and oversee hiring campaigns
          with ease. Join us in transforming recruitment into a straightforward,
          efficient experience tailored to your needs.
        </p>
      </div>
    </div>
  );
}

export default BackgroundAuth;

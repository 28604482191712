import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import fileIcon from "../../images/file/fileIcon.png";
import ButtonLoader from "../Loaders/ButtonLoader"; // Adjust the import according to your project structure
import { uploadFiles } from "../../services/apiService"; // Adjust the import according to your project structure
import { toast } from "react-toastify";

const FileDropper = ({
  title,
  instructions,
  onFileDrop,
  type,
  fileTypes,
  defaultFile,
  maxFiles = 1, // Default max files to 1 if not provided
}) => {
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const videoRefs = useRef([]); // Use an array of refs for multiple videos

  const handleVideoControl = useCallback(() => {
    videoRefs.current.forEach((video) => {
      if (video) {
        const { videoWidth, videoHeight } = video;
        console.log({ videoWidth, videoHeight });

        // Automatically open in full-screen when the video is played
        video.onplay = async () => {
          if (video.requestFullscreen) {
            await video.requestFullscreen();
          } else if (video.mozRequestFullScreen) {
            await video.mozRequestFullScreen(); // Firefox
          } else if (video.webkitRequestFullscreen) {
            await video.webkitRequestFullscreen(); // Chrome, Safari, and Opera
          } else if (video.msRequestFullscreen) {
            await video.msRequestFullscreen(); // IE/Edge
          }

          // Check if the video is in full-screen mode
          const isFullScreen = document.fullscreenElement === video;

          if (isFullScreen) {
            // If full-screen, allow all controls
            video.onplay = null; // Remove any restrictions
            video.play(); // Automatically play in full-screen
          } else if (videoWidth <= 1280 || videoHeight <= 720) {
            // If not in full-screen and the video is small, restrict controls
            video.pause();
            video.onplay = () => video.pause(); // Prevent play
          } else {
            // Allow play for larger videos outside full-screen
            video.onplay = null; // Remove any restrictions
            video.play(); // Automatically play
          }
        };
      }
    });
  }, []);

  const handleFullScreenChange = useCallback(() => {
    videoRefs.current.forEach((video) => {
      if (video) {
        const isFullScreen = document.fullscreenElement === video;
        const { videoWidth, videoHeight } = video;

        if (!isFullScreen && (videoWidth <= 1280 || videoHeight <= 720)) {
          // If exiting full-screen and the video is small, pause the video
          video.pause();
          video.onplay = () => video.pause(); // Prevent play on small screen
        }
        video.onplay = async () => {
          if (video.requestFullscreen) {
            await video.requestFullscreen();
          } else if (video.mozRequestFullScreen) {
            await video.mozRequestFullScreen(); // Firefox
          } else if (video.webkitRequestFullscreen) {
            await video.webkitRequestFullscreen(); // Chrome, Safari, and Opera
          } else if (video.msRequestFullscreen) {
            await video.msRequestFullscreen(); // IE/Edge
          }
        };
      }
    });
  }, []);

  useEffect(() => {
    const checkVideoSize = () => {
      handleVideoControl();
    };

    // Run check when component mounts and when previewFile changes
    checkVideoSize();

    // Run check whenever a video is loaded
    videoRefs.current.forEach((video) => {
      if (video) {
        video.onloadedmetadata = checkVideoSize;
      }
    });

    // Listen for window resize events to adjust video controls dynamically
    window.addEventListener("resize", checkVideoSize);

    // Listen for full-screen change events
    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      // Clean up the event listeners on component unmount
      window.removeEventListener("resize", checkVideoSize);
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [handleVideoControl, handleFullScreenChange, previewFile, droppedFiles]);

  useEffect(() => {
    if (defaultFile) {
      const fileType = defaultFile.split(".").pop(); // Get the file extension
      setPreviewFile({ fileURL: defaultFile, fileType, isDefault: true });
    } else {
      setPreviewFile(null);
    }
  }, [defaultFile]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length > maxFiles) {
        toast.error(`You can only upload a maximum of ${maxFiles} file(s).`);
        return;
      }

      const validFiles = acceptedFiles.filter((file) => {
        const fileType = file.type.split("/")[1]; // Get the file extension/type
        if (fileTypes.includes(fileType)) {
          return true;
        } else {
          toast.error(`File type ${fileType} is not supported.`);
          return false;
        }
      });

      if (validFiles.length === 0) return; // Stop if no valid files

      setDroppedFiles(validFiles);
      setLoading(true);

      try {
        // Upload valid files
        const response = await uploadFiles(validFiles);
        const uploadedFilePath = response.photos[0]; // Update based on your API response

        // Notify parent component about the uploaded file and its type
        onFileDrop(uploadedFilePath, type);
        toast.success("File uploaded successfully!");

        // Set the preview file for the first dropped file
        const fileType = validFiles[0].type.split("/")[0];
        setPreviewFile({
          fileURL: URL.createObjectURL(validFiles[0]),
          fileType,
          isDefault: false,
        });

        // Apply video control based on video size
        handleVideoControl();
      } catch (error) {
        toast.error("Failed to upload file.");
        console.error("File upload error:", error);
      } finally {
        setLoading(false);
      }
    },
    [fileTypes, maxFiles, onFileDrop, type, handleVideoControl]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles });

  return (
    <div
      {...getRootProps()}
      className={`flex flex-col items-center justify-center w-full h-32 p-6 border-2 border-dashed rounded-md cursor-pointer 
        ${
          droppedFiles.length > 0 || previewFile
            ? "border-blue-400"
            : "border-greenButton"
        }
        bg-lightGreen`}
    >
      <input {...getInputProps()} />
      <div className="flex flex-row items-center space-x-4">
        <img src={fileIcon} alt="fileIcon" className="w-8 h-8" />
        <div className="sm:text-lg text-[10px]">
          <p className="text-gray-600">{title}</p>
          <p className="text-xs text-slate-400">{instructions}</p>
        </div>
      </div>
      {loading && (
        <div className="mt-2">
          <ButtonLoader /> {/* Display the loader while uploading */}
        </div>
      )}
      {previewFile && !loading && !droppedFiles.length && (
        <div className="mt-2 flex space-x-2">
          {previewFile.fileType === "image" && (
            <img
              src={previewFile.fileURL}
              alt="preview"
              className="w-16 h-16 object-cover rounded-md"
            />
          )}
          {["mp4", "mov", "avi"].includes(previewFile.fileType) && (
            <video
              ref={(el) => (videoRefs.current[0] = el)} // Store the ref in the array
              src={previewFile.fileURL}
              className="w-16 h-16 object-cover rounded-md"
              controls
            />
          )}
          {["pdf", "doc", "docx"].includes(previewFile.fileType) && (
            <embed
              src={previewFile.fileURL}
              type="application/pdf"
              className="w-16 h-16 object-cover rounded-md"
            />
          )}
          {!["image", "mp4", "pdf"].includes(previewFile.fileType) && (
            <p className="text-xs text-gray-600">
              Default file cannot be previewed
            </p>
          )}
        </div>
      )}
      {!loading && droppedFiles.length > 0 && (
        <div className="mt-2 flex space-x-2">
          {droppedFiles.map((file, index) => {
            const fileType = file.type.split("/")[0];
            const fileURL = URL.createObjectURL(file);
            if (fileType === "image") {
              return (
                <img
                  key={file.name}
                  src={fileURL}
                  alt={file.name}
                  className="w-16 h-16 object-cover rounded-md"
                />
              );
            } else if (fileType === "video") {
              return (
                <video
                  ref={(el) => (videoRefs.current[index + 1] = el)} // Store the ref in the array
                  key={file.name}
                  src={fileURL}
                  className="w-16 h-16 object-cover rounded-md"
                  controls
                />
              );
            } else if (file.type === "application/pdf") {
              return (
                <embed
                  key={file.name}
                  src={fileURL}
                  type="application/pdf"
                  className="w-16 h-16 object-cover rounded-md"
                />
              );
            } else {
              return (
                <p key={file.name} className="text-xs text-gray-600">
                  {file.name}
                </p>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

export default FileDropper;

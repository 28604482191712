import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineModeEditOutline, MdDeleteOutline } from "react-icons/md";
import { TbGridDots } from "react-icons/tb";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { toast, ToastContainer } from "react-toastify";
import FileDropper from "../components/dropDown/FileDropper";
import ButtonLoader from "../components/Loaders/ButtonLoader";
import ConfirmationModal from "../components/confirm/confirmModel";
import {
  getcandidateHelpAndSupport,
  sortcandidateHelpAndSupport,
  deletecandidateHelpAndSupport,
  postcandidateHelpAndSupport,
  editcandidateHelpAndSupport,
} from "../services/apiService";

const ItemType = "QUESTION";

// DraggableItem component
const DraggableItem = ({
  section,
  index,
  moveSection,
  onEdit,
  onDelete,
  toggleSection,
  onDropComplete,
}) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { index },
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        onDropComplete();
      }
    },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveSection(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <div
      ref={(node) => ref(drop(node))}
      className="bg-gray-100 p-2 rounded-lg shadow-sm mb-2"
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <TbGridDots className="cursor-pointer text-gray-500" />
          <h3
            className="text-xl cursor-pointer flex items-center gap-1"
            onClick={() => toggleSection(section.id)}
          >
            <div className="bg-gray-200 border-2 border-gray-300 m-2">
              {section.isOpen ? (
                <AiOutlineMinus className="text-gray-700" />
              ) : (
                <AiOutlinePlus className="text-gray-700" />
              )}
            </div>
            {section.content.title}
          </h3>
        </div>
        <div className="flex gap-2">
          <div
            className="p-2 bg-gray-200 rounded-full cursor-pointer"
            onClick={() => onEdit(section)}
          >
            <MdOutlineModeEditOutline className="text-xl text-gray-700" />
          </div>
          <div
            className="p-2 bg-red-200 rounded-full cursor-pointer"
            onClick={() => onDelete(section)}
          >
            <MdDeleteOutline className="text-xl text-red-700" />
          </div>
        </div>
      </div>
      {section.isOpen && (
        <div className="mt-4">
          <h4 className="font-semibold">Description:</h4>
          <p className="mt-2">{section.content.description}</p>
          {section.content.video && (
            <div className="mt-4 bg-gray-200 h-56 flex items-center justify-center">
              <video width="400" controls>
                <source
                  src={
                    typeof section.content.video === "string"
                      ? section.content.video
                      : URL.createObjectURL(section.content.video)
                  }
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

// QuestionModal component
const QuestionModal = ({ isOpen, onClose, onSave, existingData }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [video, setVideo] = useState(null);

  useEffect(() => {
    if (existingData) {
      setTitle(existingData.title || "");
      setDescription(existingData.description || "");
      setVideo(existingData.video || null);
    } else {
      setTitle("");
      setDescription("");
      setVideo(null);
    }
  }, [existingData]);

  const handleSave = () => {
    onSave({ ...existingData, title, description, video });
    onClose();
  };

  const handleFileDrop = (uploadedFilePath, type) => {
    setVideo(uploadedFilePath);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded-lg w-[90%] sm:w-1/2">
        <h2 className="text-2xl mb-4">
          {existingData ? "Edit Your Question" : "Make Your Question"}
        </h2>
        <div className="flex justify-between gap-2">
          <div className="flex flex-col w-full">
            <label className="block mb-2">Title</label>
            <input
              type="text"
              className="w-full p-2 border rounded mb-4"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="flex flex-col w-full">
            <label className="block mb-2">Description</label>
            <textarea
              className="w-full p-2 border rounded mb-4"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>
        <div>
          <label className="block mb-2">Upload Video</label>
          <FileDropper
            title="Drag & drop your video here, or click to select files"
            instructions="Upload only 1 video at a time"
            onFileDrop={handleFileDrop}
            type="video"
            fileTypes={["mp4", "mov", "avi"]}
            maxFiles={1}
            defaultFile={video}
          />
        </div>
        <div className="flex justify-end gap-4 mt-3">
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

// HelpAndGuidancecandidate component
const HelpAndGuidancecandidate = () => {
  const dispatch = useDispatch();
  const guidanceList = useSelector(
    (state) => state.candidateHelpAndGuidanceReducer_admin.guidanceList
  );
  const [loading, setLoading] = useState(false);
  const [sections, setSections] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingSection, setEditingSection] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [sectionToDelete, setSectionToDelete] = useState(null);
  const [movedSectionData, setMovedSectionData] = useState(null);
  const [hasFetched, setHasFetched] = useState(false); // State to track if data has been fetched

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await dispatch(getcandidateHelpAndSupport());
        setHasFetched(true); // Set as fetched once data is received
        if (response.length === 0) {
          setSections([]); // No items found
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("Failed to fetch guidance items.");
      }
    };

    // Fetch data only if it hasn't been fetched yet
    if (!hasFetched) {
      if (guidanceList.length === 0) {
        fetchData();
      }
    }
  }, [dispatch, hasFetched, guidanceList]);

  useEffect(() => {
    setSections(
      guidanceList.map((item) => ({
        id: item._id,
        isOpen: false,
        content: item,
      }))
    );
  }, [guidanceList]);

  const toggleSection = (sectionId) => {
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === sectionId
          ? { ...section, isOpen: !section.isOpen }
          : section
      )
    );
  };

  const handleEdit = (section) => {
    setEditingSection(section.content);
    setIsModalOpen(true);
  };

  const handleSave = async (data) => {
    try {
      if (editingSection !== null) {
        await dispatch(editcandidateHelpAndSupport(data));
        setSections((prevSections) =>
          prevSections.map((section) =>
            section.id === data._id ? { ...section, content: data } : section
          )
        );
        toast.success("Help and Guide item updated successfully!");
      } else {
        const response = await dispatch(postcandidateHelpAndSupport(data));
        setSections((prevSections) => [
          ...prevSections,
          { id: response._id, isOpen: false, content: response },
        ]);
        toast.success("Help and Guide item added successfully!");
      }
    } catch (error) {
      toast.error("Failed to save the item.");
    }
    setEditingSection(null);
    setIsModalOpen(false);
  };

  const handleDelete = (section) => {
    setSectionToDelete(section);
    setIsConfirmationOpen(true);
  };

  const confirmDelete = async () => {
    if (sectionToDelete) {
      try {
        await dispatch(
          deletecandidateHelpAndSupport({ id: sectionToDelete.id })
        );
        toast.success("Successfully deleted the item.");
        setSections((prevSections) =>
          prevSections.filter((section) => section.id !== sectionToDelete.id)
        );
      } catch (error) {
        toast.error("Failed to delete the item.");
      } finally {
        setIsConfirmationOpen(false);
        setSectionToDelete(null);
      }
    }
  };

  const moveSection = (fromIndex, toIndex) => {
    const updatedSections = [...sections];
    const [movedSection] = updatedSections.splice(fromIndex, 1);
    updatedSections.splice(toIndex, 0, movedSection);
    setSections(updatedSections);

    setMovedSectionData({
      id: movedSection.id,
      sort: toIndex + 1,
    });
  };

  const handleDropComplete = async () => {
    if (movedSectionData) {
      await dispatch(sortcandidateHelpAndSupport(movedSectionData));
      setMovedSectionData(null);
    }
  };

  return (
    <div>
      <ToastContainer position="top-right" autoClose={5000} />
      <div className="bg-white shadow-md rounded-md p-6">
        <div className="flex justify-between mb-4">
          <h2 className="text-2xl font-semibold">Help & Guide</h2>
          <button
            className="bg-purpleButton text-white px-4 py-2 rounded-full"
            onClick={() => {
              setEditingSection(null);
              setIsModalOpen(true);
            }}
          >
            Add Question
          </button>
        </div>
        {loading ? (
          <div className="flex justify-center items-center">
            <ButtonLoader />
          </div>
        ) : (
          <DndProvider backend={HTML5Backend}>
            {sections.map((section, index) => (
              <DraggableItem
                key={section.id}
                section={section}
                index={index}
                moveSection={moveSection}
                onEdit={handleEdit}
                onDelete={handleDelete}
                toggleSection={toggleSection}
                onDropComplete={handleDropComplete}
              />
            ))}
          </DndProvider>
        )}
        {!loading && sections.length === 0 && (
          <div className="flex justify-center">No items yet</div>
        )}
      </div>
      <QuestionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSave}
        existingData={editingSection}
      />
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        onConfirm={confirmDelete}
        question="Are you sure you want to delete this item?"
      />
    </div>
  );
};

export default HelpAndGuidancecandidate;

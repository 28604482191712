import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify"; // Import toast from react-toastify
import ButtonLoader from "../components/Loaders/ButtonLoader"; // Import your custom ButtonLoader
import { updateEmployer } from "../services/apiService"; // Import your API function
import "react-toastify/dist/ReactToastify.css"; // Import react-toastify CSS

const EditEmployer = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { id, email: initialEmail, credit: initialCredit } = location.state; // Get state from useLocation

  const [email, setEmail] = useState(initialEmail || "");
  const [credit, setCredit] = useState(initialCredit || 0);
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleCreditChange = (e) => {
    setCredit(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = {
      id, // Employer ID
      email,
      credit: Number(credit),
    };

    try {
      await dispatch(
        updateEmployer({
          employerId: payload.id,
          newEmail: payload.email,
          credits: payload.credit,
        })
      );
      toast.success("Employer details updated successfully!");

      // Update the state with the new data after successful submission
      setEmail(email);
      setCredit(credit);
    } catch (error) {
      console.error("Failed to update employer details", error);
      toast.error("Failed to update employer.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white shadow-md rounded-md">
      <ToastContainer />
      <h2 className="text-xl font-bold mb-4">Edit Employer</h2>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="credit"
            >
              Credits
            </label>
            <input
              type="number"
              id="credit"
              value={credit}
              onChange={handleCreditChange}
              className="w-full px-3 py-2 border rounded"
              min="0"
            />
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              disabled={loading}
            >
              {loading ? <ButtonLoader /> : "Update"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditEmployer;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import ButtonLoader from "../components/Loaders/ButtonLoader";
import {
  getAllPackages,
  createPackage,
  updatePackage,
  removePackage,
} from "../services/apiService";

// Package Component
import Package from "../components/subscription/Package";

// AddPackageForm Component
const AddPackageForm = ({
  addPackage,
  editPackage,
  packages,
  editingIndex,
  setShowModal,
  isActionLoading,
}) => {
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [maxCredits, setMaxCredits] = useState("");
  const [features, setFeatures] = useState([]);
  const [featureInput, setFeatureInput] = useState("");

  useEffect(() => {
    if (editingIndex !== null) {
      const pkg = packages[editingIndex];
      setTitle(pkg.title);
      setPrice(pkg.pricePerCredit);
      setMaxCredits(pkg.numberOfCredits);
      setFeatures(pkg.features);
    } else {
      setTitle("");
      setPrice("");
      setMaxCredits("");
      setFeatures([]);
    }
  }, [editingIndex, packages]);

  const handleAddFeature = () => {
    if (featureInput) {
      setFeatures([...features, featureInput]);
      setFeatureInput("");
    }
  };

  const handleRemoveFeature = (index) => {
    setFeatures(features.filter((_, i) => i !== index));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (title && features.length > 0 && maxCredits) {
      if (editingIndex !== null) {
        if (!(packages[editingIndex]?.type === 0)) {
          if (!price) {
            toast.error("price must be greator than zero");
            return;
          }
        }
      } else {
        if (!price) {
          toast.error("price must be greator than zero");
          return;
        }
      }
      const newPackage = {
        title,
        features,
        pricePerCredit: price,
        numberOfCredits: maxCredits,
      };

      if (editingIndex !== null) {
        editPackage({ ...newPackage, _id: packages[editingIndex]._id });
      } else {
        addPackage(newPackage);
      }

      setShowModal(false);
    } else {
      toast.error("Please fill in all the required fields.");
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-4 rounded-lg shadow-lg w-11/12 md:w-3/4 lg:w-1/2">
        <h2 className="text-xl font-semibold mb-2">
          {editingIndex !== null ? "Edit Plan" : "Add Plan"}
        </h2>
        <form onSubmit={handleSubmit} className="flex flex-col">
          <div className="flex flex-col">
            <div className="flex flex-col sm:flex-row md:flex-col gap-2">
              <div className="w-full">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Title
                </label>
                <input
                  type="text"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="flex flex-row gap-2">
                <div className="w-1/2">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="features"
                  >
                    Features
                  </label>
                  <div className="flex gap-2">
                    <input
                      id="features"
                      type="text"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      value={featureInput}
                      onChange={(e) => setFeatureInput(e.target.value)}
                    />
                    <button
                      type="button"
                      className="bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      onClick={handleAddFeature}
                    >
                      Add
                    </button>
                  </div>
                  <ul className="mt-2 max-h-10 overflow-y-scroll">
                    {features.map((feature, index) => (
                      <li
                        key={index}
                        className="flex justify-between items-center bg-gray-100 px-2 py-1 rounded mt-1"
                      >
                        <div className="truncate">{feature}</div>
                        <button
                          type="button"
                          className="text-red-500 font-bold"
                          onClick={() => handleRemoveFeature(index)}
                        >
                          &times;
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="flex gap-2">
                {!(price === null) && (
                  <div className="w-1/2">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="price"
                    >
                      Price per Credit (USD)
                    </label>
                    <input
                      id="price"
                      type="number"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      value={price}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0 || value === "") {
                          setPrice(value);
                        }
                      }}
                    />
                    <p className="text-gray-600 text-xs italic mt-1">
                      Amount charged per credit.
                    </p>
                  </div>
                )}

                <div className="w-1/2">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="maxCredits"
                  >
                    Number of Credits
                  </label>
                  <input
                    id="maxCredits"
                    type="number"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={maxCredits}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value > 0 || value === "") {
                        setMaxCredits(value);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center mt-2 gap-3 text-sm">
            {isActionLoading ? (
              <ButtonLoader />
            ) : (
              <>
                <button
                  type="submit"
                  className="bg-purpleButton text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  {editingIndex !== null ? "Save Changes" : "Save Plan"}
                </button>
                <button
                  type="button"
                  className="bg-red-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

// Subscriptions Component
function Subscriptions() {
  const packages = useSelector(
    (state) => state?.packagesReducer_admin?.packages
  );
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [slidesToShow, setSlidesToShow] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [currentAction, setCurrentAction] = useState("");

  useEffect(() => {
    if (packages.length === 0) {
      const fetchPackages = async () => {
        setIsLoading(true);
        try {
          await dispatch(getAllPackages());
          toast.success("Packages retrieved successfully!");
        } catch (error) {
          // toast.error("Failed to retrieve packages. Please try again later.");
        } finally {
          setIsLoading(false);
        }
      };

      fetchPackages();
    } else {
      setIsLoading(false);
    }
  }, [dispatch, packages]);

  useEffect(() => {
    const handleResize = () => {
      setSlidesToShow(window.innerWidth >= 640 ? 2 : 1);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleAddPackage = async (newPackage) => {
    setCurrentAction("add");
    setIsActionLoading(true);
    try {
      await dispatch(createPackage(newPackage));
      toast.success("Package added successfully!");
    } catch (error) {
      toast.error("Failed to add package. Please try again later.");
    } finally {
      setIsActionLoading(false);
      setCurrentAction("");
    }
  };

  const handleEditPackage = async (updatedPackage) => {
    setCurrentAction(`edit-${updatedPackage._id}`);
    setIsActionLoading(true);
    try {
      const response = await dispatch(updatePackage(updatedPackage));
      toast.success(response.message);

      // Update the local packages array directly
      dispatch({
        type: "UPDATE_PACKAGE",
        payload: updatedPackage,
      });
    } catch (error) {
      toast.error("Failed to update package. Please try again later.");
    } finally {
      setIsActionLoading(false);
      setCurrentAction("");
    }
  };

  const handleDeletePackage = async (packageId) => {
    setCurrentAction(`delete-${packageId}`);
    setIsActionLoading(true);
    try {
      await dispatch(removePackage(packageId));
      toast.success("Package deleted successfully!");

      // Remove the deleted package from the local packages array
      dispatch({
        type: "DELETE_PACKAGE",
        payload: packageId,
      });
    } catch (error) {
      toast.error("Failed to delete package. Please try again later.");
    } finally {
      setIsActionLoading(false);
      setCurrentAction("");
    }
  };

  const nextSlide = () => {
    if (containerRef.current) {
      const newScrollPosition =
        containerRef.current.scrollLeft +
        containerRef.current.clientWidth * slidesToShow;
      containerRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });
    }
  };

  const prevSlide = () => {
    if (containerRef.current) {
      const newScrollPosition =
        containerRef.current.scrollLeft -
        containerRef.current.clientWidth * slidesToShow;
      containerRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });
    }
  };

  const editPackage = (index) => {
    setEditingIndex(index);
    setShowModal(true);
  };

  const togglePackageActive = async (index) => {
    setCurrentAction(`toggle-${index}`);
    setIsActionLoading(true);
    try {
      const packageToUpdate = {
        ...packages[index],
        active: !packages[index].active,
      };
      await handleEditPackage(packageToUpdate);
    } catch (error) {
      toast.error("Failed to update package status. Please try again later.");
    } finally {
      setIsActionLoading(false);
      setCurrentAction("");
    }
  };

  return (
    <div className="flex flex-col items-center shadow-xl">
      <ToastContainer position="top-right" autoClose={5000} />
      <div className="font-semibold text-center text-2xl mb-4">Credits</div>
      {isLoading ? (
        <ButtonLoader />
      ) : (
        <div className="flex w-full relative">
          <div
            ref={containerRef}
            className="relative w-full overflow-x-scroll hide-scrollbar"
          >
            <div
              style={{
                display: "flex",
                transition: "transform 0.5s ease-in-out",
              }}
            >
              {packages.map((pkg, index) => (
                <div key={index}>
                  <div className="w-60 m-auto">
                    <Package
                      title={pkg.title}
                      price={pkg.pricePerCredit ? pkg.pricePerCredit : 0}
                      features={pkg.features}
                      maxCredits={pkg.numberOfCredits}
                      freeCredits={pkg.freeCredits}
                      freeCreditsDuration={pkg.freeCreditsDuration}
                      index={index}
                      deletePackage={() => handleDeletePackage(pkg._id)}
                      editPackage={() => editPackage(index)}
                      active={pkg.active}
                      togglePackageActive={() => togglePackageActive(index)}
                      isLoading={isActionLoading}
                      currentAction={currentAction}
                      packageId={pkg._id}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button
            onClick={prevSlide}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2"
            style={{ zIndex: 1 }}
          >
            &lt;
          </button>
          <button
            onClick={nextSlide}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2"
            style={{ zIndex: 1 }}
          >
            &gt;
          </button>
          <button
            onClick={() => {
              setShowModal(true);
              setEditingIndex(null);
            }}
            className="text-purpleButton text-4xl font-bold ml-4 mr-8"
          >
            Add
          </button>
        </div>
      )}
      {showModal && (
        <AddPackageForm
          addPackage={handleAddPackage}
          editPackage={handleEditPackage}
          packages={packages}
          editingIndex={editingIndex}
          setShowModal={setShowModal}
          isActionLoading={isActionLoading && currentAction === "add"}
        />
      )}
      <style>{`
        .hide-scrollbar {
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
        }
        .hide-scrollbar::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
      `}</style>
    </div>
  );
}

export default Subscriptions;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; // Use location to get employerId
import ButtonLoader from "../components/Loaders/ButtonLoader"; // Assuming you have this component
import { GoPlus } from "react-icons/go";
import { FiMinus } from "react-icons/fi";
import { getSubscription } from "../services/apiService"; // Import the getSubscription API
import { useDispatch } from "react-redux";

const TransactionHistoryEmployer = () => {
  const location = useLocation();
  const { id: employerId } = location.state; // Get employer ID from state

  const [loading, setLoading] = useState(true);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [error, setError] = useState("");
  const [expandedRow, setExpandedRow] = useState(null);
  const [isCurrentPackageExpanded, setIsCurrentPackageExpanded] =
    useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      setLoading(true);
      try {
        // Call API to get the subscription data
        const data = await dispatch(getSubscription({ id: employerId }));

        setSubscriptionData(data); // Set the API response data to state
      } catch (err) {
        setError("Failed to fetch subscription data");
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionData();
  }, [employerId, dispatch]);

  const handleRowExpand = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const handleCurrentPackageExpand = () => {
    setIsCurrentPackageExpanded(!isCurrentPackageExpanded);
  };

  if (loading) return <ButtonLoader />;
  if (error) return <div className="text-red-600">{error}</div>;

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-xl font-bold mb-4">Employer Subscription Details</h2>

      {/* Current Package Section */}
      <div className="mb-6">
        <h3 className="font-semibold">Current Package:</h3>

        {/* Desktop and Tablet */}
        <div className="hidden md:block">
          <table className="min-w-full text-xs lg:text-sm border rounded-lg">
            <thead>
              <tr className="text-left text-gray-400">
                <th className="p-2">Transaction ID</th>
                <th className="p-2">Package Title</th>
                <th className="p-2">Features</th>
                <th className="p-2">Price Per Credit</th>
                <th className="p-2">Number of Credits</th>
                <th className="p-2">Availiable Credits</th>
                <th className="p-2">Admin Added Credits</th>
                <th className="p-2">Admin Removed Credits</th>
                <th className="p-2">Status</th>
              </tr>
            </thead>
            <tbody className="text-gray-700">
              <tr>
                <td className="p-2">
                  {subscriptionData.currentPackage.transactionId
                    ? subscriptionData.currentPackage.transactionId
                    : "FREE"}
                </td>
                <td className="p-2">{subscriptionData.currentPackage.title}</td>
                <td className="p-2">
                  {subscriptionData.currentPackage.features.join(", ")}
                </td>
                <td className="p-2">
                  ${subscriptionData.currentPackage.pricePerCredit}
                </td>
                <td className="p-2">
                  {subscriptionData.currentPackage.numberOfCredits}
                </td>
                <td className="p-2">
                  {
                    subscriptionData.currentPackage.packageStatus
                      .numberOfCredits
                  }
                </td>
                <td className="p-2">
                  {
                    subscriptionData.currentPackage.packageStatus
                      .numberOfCreditsAdminCustomAdded
                  }
                </td>
                <td className="p-2">
                  {
                    subscriptionData.currentPackage.packageStatus
                      .numberOfCreditsAdminCustomRemove
                  }
                </td>
                <td className="p-2">{"ACTIVE"}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Mobile */}
        <div className="md:hidden">
          <div className="border-t p-2">
            <div className="flex justify-between items-center">
              <div>
                <strong>Transaction ID:</strong>{" "}
                {subscriptionData.currentPackage.transactionId
                  ? subscriptionData.currentPackage.transactionId
                  : "FREE"}
              </div>
              <div>
                {isCurrentPackageExpanded ? (
                  <FiMinus
                    className="cursor-pointer bg-red-700 text-white rounded-full w-6 h-6"
                    onClick={handleCurrentPackageExpand}
                  />
                ) : (
                  <GoPlus
                    className="cursor-pointer bg-blue-700 text-white rounded-full w-6 h-6"
                    onClick={handleCurrentPackageExpand}
                  />
                )}
              </div>
            </div>
            {isCurrentPackageExpanded && (
              <div className="mt-2">
                <p>
                  <strong>Package Title:</strong>{" "}
                  {subscriptionData.currentPackage.title}
                </p>
                <p>
                  <strong>Features:</strong>{" "}
                  {subscriptionData.currentPackage.features.join(", ")}
                </p>
                <p>
                  <strong>Price Per Credit:</strong> $
                  {subscriptionData.currentPackage.pricePerCredit}
                </p>
                <p>
                  <strong>Number of Credits:</strong>{" "}
                  {subscriptionData.currentPackage.numberOfCredits}
                </p>
                <p>
                  <strong>Availiable Credits:</strong>{" "}
                  {
                    subscriptionData.currentPackage.packageStatus
                      .numberOfCredits
                  }
                </p>
                <p>
                  <strong>Admin Added Credits:</strong>{" "}
                  {
                    subscriptionData.currentPackage.packageStatus
                      .numberOfCreditsAdminCustomAdded
                  }
                </p>
                <p>
                  <strong>Admin Removed Credits:</strong>{" "}
                  {
                    subscriptionData.currentPackage.packageStatus
                      .numberOfCreditsAdminCustomRemove
                  }
                </p>
                <p>
                  <strong>Status:</strong>
                  {"ACTIVE"}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Transaction History Section */}
      <div>
        <h3 className="font-semibold mb-4">Transaction History:</h3>
        {subscriptionData.subscriptionHistory.length > 0 ? (
          <>
            {/* Desktop and Tablet */}
            <div className="hidden md:block">
              <table className="min-w-full text-xs lg:text-sm border rounded-lg">
                <thead>
                  <tr className="text-left text-gray-400">
                    <th className="p-2">Transaction ID</th>
                    <th className="p-2">Package Title</th>
                    <th className="p-2">Features</th>
                    <th className="p-2">Price Per Credit</th>
                    <th className="p-2">Number of Credits</th>
                    <th className="p-2">Availiable Credits</th>
                    <th className="p-2">Admin Added Credits</th>
                    <th className="p-2">Admin Removed Credits</th>
                    <th className="p-2">Status</th>
                  </tr>
                </thead>
                <tbody className="text-gray-700">
                  {subscriptionData.subscriptionHistory.map(
                    (transaction, index) => (
                      <tr key={index} className="border-t">
                        <td className="p-2">
                          {transaction.transactionId
                            ? transaction.transactionId
                            : "FREE"}
                        </td>
                        <td className="p-2">{transaction.title}</td>
                        <td className="p-2">
                          {transaction.features.join(", ")}
                        </td>
                        <td className="p-2">${transaction.pricePerCredit}</td>
                        <td className="p-2">{transaction.numberOfCredits}</td>
                        <td className="p-2">
                          {transaction.packageStatus.numberOfCredits}
                        </td>
                        <td className="p-2">
                          {
                            transaction.packageStatus
                              .numberOfCreditsAdminCustomAdded
                          }
                        </td>
                        <td className="p-2">
                          {
                            transaction.packageStatus
                              .numberOfCreditsAdminCustomRemove
                          }
                        </td>
                        <td className="p-2">{"EXPIRED"}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>

            {/* Mobile */}
            <div className="md:hidden">
              {subscriptionData.subscriptionHistory.map(
                (transaction, index) => (
                  <div key={index} className="border-t p-2">
                    <div className="flex justify-between items-center">
                      <div>
                        <strong>Transaction ID:</strong>{" "}
                        {transaction.transactionId
                          ? transaction.transactionId
                          : "FREE"}
                      </div>
                      <div>
                        {expandedRow === index ? (
                          <FiMinus
                            className="cursor-pointer bg-red-700 text-white rounded-full w-6 h-6"
                            onClick={() => handleRowExpand(index)}
                          />
                        ) : (
                          <GoPlus
                            className="cursor-pointer bg-blue-700 text-white rounded-full w-6 h-6"
                            onClick={() => handleRowExpand(index)}
                          />
                        )}
                      </div>
                    </div>
                    {expandedRow === index && (
                      <div className="mt-2">
                        <p>
                          <strong>Package Title:</strong> {transaction.title}
                        </p>
                        <p>
                          <strong>Features:</strong>{" "}
                          {transaction.features.join(", ")}
                        </p>
                        <p>
                          <strong>Price Per Credit:</strong> $
                          {transaction.pricePerCredit}
                        </p>
                        <p>
                          <strong>Number of Credits:</strong>{" "}
                          {transaction.numberOfCredits}
                        </p>
                        <p>
                          <strong>Availiable Credits:</strong>{" "}
                          {transaction.packageStatus.numberOfCredits}
                        </p>
                        <p>
                          <strong>Admin Added Credits:</strong>{" "}
                          {
                            transaction.packageStatus
                              .numberOfCreditsAdminCustomAdded
                          }
                        </p>
                        <p>
                          <strong>Admin Removed Credits:</strong>
                          {
                            transaction.packageStatus
                              .numberOfCreditsAdminCustomRemove
                          }
                        </p>
                        <p>
                          <strong>Status:</strong>
                          {"EXPIRED"}
                        </p>
                      </div>
                    )}
                  </div>
                )
              )}
            </div>
          </>
        ) : (
          <p>No transaction history available.</p>
        )}
      </div>
    </div>
  );
};

export default TransactionHistoryEmployer;

import React, { useEffect, useState } from "react";
import { FiMinus } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
import StatsComponent from "../components/Statistics/StatsComponent";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import {
  jobApplicationRecentActivity,
  toggleBlockUnBloack,
  softDeleteCandidate, // Import the delete API
} from "../services/apiService"; // Update with your correct import path
import ButtonLoader from "../components/Loaders/ButtonLoader"; // Import your custom ButtonLoader component
import { useDispatch } from "react-redux";
import { generateConsistentAlphanumericfunction } from "../components/functions/generateText";
import { useNavigate } from "react-router-dom";

const Statistics = () => {
  const [candidatesPerPage, setCandidatesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedRow, setExpandedRow] = useState(null);
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonLoadingIndex, setButtonLoadingIndex] = useState(null);
  const [deleteLoadingIndex, setDeleteLoadingIndex] = useState(null); // State to handle delete loading
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchRecentApplications = async () => {
      setLoading(true);
      try {
        const response = await dispatch(
          jobApplicationRecentActivity({
            page: currentPage,
            limit: candidatesPerPage,
          })
        );
        setCandidates(response.jobApplications);
        setTotalPages(response.pagination.totalPages);
        setTotalItems(response.pagination.totalItems);
      } catch (error) {
        console.error("Failed to fetch recent applications", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRecentApplications();
  }, [currentPage, candidatesPerPage, dispatch]);

  const handleCandidatesPerPageChange = (event) => {
    setCandidatesPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page whenever candidates per page changes
  };

  const handleRowExpand = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const handleDropdownToggle = (index) => {
    setDropdownIndex(dropdownIndex === index ? null : index);
  };

  const handleEnableDisableToggle = async (index, candidateId) => {
    setButtonLoadingIndex(index);
    try {
      await dispatch(toggleBlockUnBloack({ id: candidateId }));
      setCandidates((prevCandidates) =>
        prevCandidates.map((candidate) =>
          candidate.candidate._id === candidateId
            ? {
                ...candidate,
                candidate: {
                  ...candidate.candidate,
                  isBlocked: !candidate.candidate.isBlocked,
                },
              }
            : candidate
        )
      );
    } catch (error) {
      console.error("Failed to toggle block/unblock candidate", error);
    } finally {
      setButtonLoadingIndex(null);
      setDropdownIndex(null); // Close dropdown after action
    }
  };

  const handleDeleteCandidate = async (index, candidateId) => {
    setDeleteLoadingIndex(index);
    try {
      await dispatch(softDeleteCandidate({ id: candidateId }));
      setCandidates((prevCandidates) =>
        prevCandidates.map((candidate) =>
          candidate.candidate._id === candidateId
            ? {
                ...candidate,
                candidate: {
                  ...candidate.candidate,
                  isDeleted: true, // Mark as deleted
                },
              }
            : candidate
        )
      );
    } catch (error) {
      console.error("Failed to delete candidate", error);
    } finally {
      setDeleteLoadingIndex(null);
      setDropdownIndex(null); // Close dropdown after action
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 0:
        return "bg-[#fcd9e2] text-[#f1416c]";
      case 1:
        return "bg-green-200 text-green-600";
      case 2:
        return "bg-[#ccf4f1] text-[#00c9b7]";
      case 3:
        return "bg-[#fff4d0] text-[#ffc715]";
      case 4:
        return "bg-[#ccf4f1] text-[#00c9b7]";
      case 5:
        return "bg-blue-200 text-blue-600";
      case 6:
        return "bg-green-200 text-green-600";
      default:
        return "bg-[#ccf4f1] text-[#00c9b7]";
    }
  };

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <div>
      <StatsComponent />
      <div className="flex flex-col">
        <div className="font-bold text-lg mb-4">Recent Activities</div>
      </div>
      {loading ? (
        <ButtonLoader />
      ) : (
        <div>
          {/* Desktop and Tablet */}
          <div className="hidden sm:flex flex-col">
            <table className="min-w-full text-xs lg:text-sm">
              <thead>
                <tr className="text-left text-gray-400">
                  <th className="p-2">JOB REF. NO</th>
                  <th className="p-2">CANDIDATE</th>
                  <th className="p-2">STATUS</th>
                  <th className="p-2">ENABLE/DISABLE</th>
                  <th className="p-2">CANDIDATE STATUS</th> {/* New Column */}
                  <th className="p-2">ACTION</th>
                </tr>
              </thead>
              <tbody className="text-gray-700">
                {candidates.map((candidate, index) => (
                  <tr key={index} className="border-t">
                    <td className="p-2 font-bold">
                      {generateConsistentAlphanumericfunction(candidate.jobId)}
                    </td>
                    <td className="p-2">
                      <div className="font-bold">
                        {candidate.candidate.first_name}{" "}
                        {candidate.candidate.last_name}
                      </div>
                      <div className="text-gray-500">
                        {candidate.candidate.email}
                      </div>
                    </td>
                    <td className="p-2">
                      <span
                        className={`px-4 py-1 rounded-2xl ${getStatusClass(
                          candidate.success === 1 ? 6 : candidate.status
                        )}`}
                      >
                        {candidate.success === 2 ? (
                          "rejected"
                        ) : candidate.success === 1 ? (
                          "Hired"
                        ) : (
                          <>
                            {candidate.status === 0 && "pending"}
                            {candidate.status === 1 && "accepted"}
                            {candidate.status === 3 && "test taken"}
                            {candidate.status === 4 && "passed"}
                            {candidate.status === 5 && "contract signed"}
                          </>
                        )}
                      </span>
                    </td>
                    {/* Enable/Disable Column */}
                    <td className="p-2">
                      {!candidate.candidate.isBlocked ? (
                        <div className="text-green-600">Enabled</div>
                      ) : (
                        <div className="text-red-600">Disabled</div>
                      )}
                    </td>
                    {/* Candidate Status Column */}
                    <td className="p-2">
                      {!candidate.candidate.isDeleted ? (
                        <span className="text-green-600">Active</span>
                      ) : (
                        <span className="text-red-600">Deleted</span>
                      )}
                    </td>
                    <td className="p-2 relative">
                      <BsThreeDotsVertical
                        className="cursor-pointer"
                        onClick={() => handleDropdownToggle(index)}
                      />
                      {dropdownIndex === index && (
                        <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-10">
                          <div
                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => {
                              navigate("/index/editCandidate", {
                                state: {
                                  candidateId: candidate.candidate._id,
                                  email: candidate.candidate.email,
                                },
                              });
                              setDropdownIndex(null);
                            }}
                          >
                            Edit
                          </div>
                          <div
                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                            onClick={() =>
                              handleEnableDisableToggle(
                                index,
                                candidate.candidate._id
                              )
                            }
                          >
                            {buttonLoadingIndex === index ? (
                              <ButtonLoader />
                            ) : candidate.candidate.isBlocked ? (
                              "Enable"
                            ) : (
                              "Disable"
                            )}
                          </div>
                          <div
                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                            onClick={() =>
                              handleDeleteCandidate(
                                index,
                                candidate.candidate._id
                              )
                            }
                          >
                            {deleteLoadingIndex === index ? (
                              <ButtonLoader />
                            ) : (
                              "Delete"
                            )}
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Mobile */}
          <div className="flex sm:hidden flex-col">
            <table className="min-w-full text-xs lg:text-sm">
              <thead>
                <tr className="text-left text-gray-400">
                  <th className="p-2">JOB REF. NO</th>
                  <th className="p-2">ACTION</th>
                </tr>
              </thead>
              <tbody className="text-gray-700">
                {candidates.map((candidate, index) => (
                  <React.Fragment key={index}>
                    <tr className="border-t">
                      <td className="p-2 font-bold">
                        <div className="flex items-center">
                          {expandedRow === index ? (
                            <div className="flex items-center">
                              <div
                                className="cursor-pointer p-1 rounded-full bg-red-500 text-white"
                                onClick={() => handleRowExpand(index)}
                              >
                                <FiMinus />
                              </div>
                            </div>
                          ) : (
                            <div className="flex items-center">
                              <div
                                className="cursor-pointer p-1 rounded-full bg-purpleButton text-white"
                                onClick={() => handleRowExpand(index)}
                              >
                                <GoPlus />
                              </div>
                            </div>
                          )}
                          <div className="ml-2">
                            {generateConsistentAlphanumericfunction(
                              candidate.jobId
                            )}
                          </div>
                        </div>
                      </td>
                      <td className="p-2 relative">
                        <BsThreeDotsVertical
                          className="cursor-pointer"
                          onClick={() => handleDropdownToggle(index)}
                        />
                        {dropdownIndex === index && (
                          <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-10">
                            <div
                              className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => {
                                navigate("/index/editCandidate", {
                                  state: {
                                    candidateId: candidate.candidate._id,
                                    email: candidate.candidate.email,
                                  },
                                });
                                setDropdownIndex(null);
                              }}
                            >
                              Edit
                            </div>
                            <div
                              className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() =>
                                handleEnableDisableToggle(
                                  index,
                                  candidate.candidate._id
                                )
                              }
                            >
                              {buttonLoadingIndex === index ? (
                                <ButtonLoader />
                              ) : candidate.candidate.isBlocked ? (
                                "Enable"
                              ) : (
                                "Disable"
                              )}
                            </div>
                            <div
                              className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() =>
                                handleDeleteCandidate(
                                  index,
                                  candidate.candidate._id
                                )
                              }
                            >
                              {deleteLoadingIndex === index ? (
                                <ButtonLoader />
                              ) : (
                                "Delete"
                              )}
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                    {expandedRow === index && (
                      <tr>
                        <td colSpan={2} className="p-2">
                          <div className="flex flex-col space-y-2">
                            <div>
                              <div className="font-bold">CANDIDATE:</div>
                              <div className="font-bold">
                                {candidate.candidate.first_name}{" "}
                                {candidate.candidate.last_name}
                              </div>
                              <div className="text-gray-500">
                                {candidate.candidate.email}
                              </div>
                            </div>
                            <div className="flex flex-col text-left gap-1">
                              <div className="font-bold">STATUS:</div>
                              <div>
                                <span
                                  className={`px-4 py-1 rounded-2xl items-center ${getStatusClass(
                                    candidate.success === 1
                                      ? 6
                                      : candidate.status
                                  )}`}
                                >
                                  {candidate.success === 2 ? (
                                    "rejected"
                                  ) : candidate.success === 1 ? (
                                    "Hired"
                                  ) : (
                                    <>
                                      {candidate.status === 0 && "pending"}
                                      {candidate.status === 1 && "accepted"}
                                      {candidate.status === 3 && "test taken"}
                                      {candidate.status === 4 && "passed"}
                                      {candidate.status === 5 &&
                                        "contract signed"}
                                    </>
                                  )}
                                </span>
                              </div>
                              <div className="flex flex-col">
                                <td className="font-bold">Enable/Disable</td>
                                <td className="p-2">
                                  {!candidate.candidate.isBlocked ? (
                                    <div className="text-green-600">
                                      Enabled
                                    </div>
                                  ) : (
                                    <div className="text-red-600">Disabled</div>
                                  )}
                                </td>
                              </div>
                              {/* Candidate Status for Mobile */}
                              <div className="flex flex-col mt-2">
                                <div className="font-bold">
                                  CANDIDATE STATUS:
                                </div>
                                {!candidate.candidate.isDeleted ? (
                                  <span className="text-green-600">Active</span>
                                ) : (
                                  <span className="text-red-600">Deleted</span>
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination Controls */}
          <div className="flex justify-center sm:justify-between items-center mt-4 text-sm">
            <div className="hidden sm:flex items-center space-x-2">
              <select
                className="p-2 border rounded bg-gray-100"
                onChange={handleCandidatesPerPageChange}
                value={candidatesPerPage}
              >
                <option value="2">2</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
              <span>
                Showing {(currentPage - 1) * candidatesPerPage + 1} to{" "}
                {Math.min(currentPage * candidatesPerPage, totalItems)} of{" "}
                {totalItems} records
              </span>
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => paginate(currentPage - 1)}
                className={`px-2 py-1 rounded ${
                  currentPage === 1
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-gray-200 text-gray-600"
                }`}
                disabled={currentPage === 1}
              >
                <FaAngleLeft />
              </button>
              <button
                key={currentPage}
                onClick={() => paginate(currentPage)}
                className="px-3 py-1 rounded bg-purpleButton text-white"
              >
                {currentPage}
              </button>
              <button
                onClick={() => paginate(currentPage + 1)}
                className={`px-2 py-1 rounded ${
                  currentPage === totalPages
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-gray-200 text-gray-600"
                }`}
                disabled={currentPage === totalPages}
              >
                <FaAngleRight />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Statistics;

import { createBrowserRouter } from "react-router-dom";
// pages
import AdminIndex from "../pages/AdminIndex";
import Statistics from "../pages/Statistics";
import Candidates from "../pages/Candidates";
import Employer from "../pages/Employer";
import Subscriptions from "../pages/Subscriptions";
import HelpAndGuidanceCandidate from "../pages/HelpAndGuidanceCandidate";
import Payments from "../pages/Payments";
import TestPermissions from "../pages/TestPermissions";
// auth
import LoginAdmin from "../pages/auth/LoginAdmin";
import HelpAndGuidanceEmployer from "../pages/HelpAndGuidanceEmployer";
import EditCandidate from "../pages/EditCandidate";
import EditEmployer from "../pages/EditEmployer";
import TransactionHistoryEmployer from "../pages/TransactionHistoryEmployer";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginAdmin />,
    errorElement: <>error</>,
    children: [],
  },
  {
    path: "/index",
    element: <AdminIndex />,
    errorElement: <>error</>,
    children: [
      {
        path: "/index",
        element: <Statistics />,
        errorElement: <>error</>,
      },
      {
        path: "/index/candidates",
        element: <Candidates />,
        errorElement: <>error</>,
      },
      {
        path: "/index/employers",
        element: <Employer />,
        errorElement: <>error</>,
      },
      {
        path: "/index/subscriptions",
        element: <Subscriptions />,
        errorElement: <>error</>,
      },
      {
        path: "/index/helpandguidancecandidate",
        element: <HelpAndGuidanceCandidate />,
        errorElement: <>error</>,
      },
      {
        path: "/index/helpandguidanceemployer",
        element: <HelpAndGuidanceEmployer />,
        errorElement: <>error</>,
      },
      {
        path: "/index/payments",
        element: <Payments />,
        errorElement: <>error</>,
      },
      {
        path: "/index/testPermissions",
        element: <TestPermissions />,
        errorElement: <>error</>,
      },
      {
        path: "/index/editCandidate",
        element: <EditCandidate />,
        errorElement: <>error</>,
      },
      {
        path: "/index/editEmployer/",
        element: <EditEmployer />,
        errorElement: <>error</>,
      },
      {
        path: "/index/transactionHistoryEmployer",
        element: <TransactionHistoryEmployer />,
        errorElement: <>error</>,
      },
    ],
  },
]);

export default router;

import React, { useState, useEffect } from "react";
import { FiMinus } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import {
  candidatesAdmin,
  toggleBlockUnBloack,
  softDeleteCandidate,
} from "../services/apiService"; // Update with your correct import path
import ButtonLoader from "../components/Loaders/ButtonLoader"; // Import your custom ButtonLoader component
import { toast } from "react-toastify"; // Import toast for notifications

const Candidates = () => {
  const [expandedRow, setExpandedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [candidatesPerPage, setCandidatesPerPage] = useState(10);
  const [filterStatus, setFilterStatus] = useState(0); // Start with "all" filter
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [enableDisableLoadingIndex, setEnableDisableLoadingIndex] =
    useState(null); // Separate state for enable/disable button
  const [deleteLoadingIndex, setDeleteLoadingIndex] = useState(null); // Separate state for delete button
  const [candidates, setCandidates] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Use navigate for routing

  useEffect(() => {
    const fetchCandidates = async () => {
      setLoading(true);
      try {
        const response = await dispatch(
          candidatesAdmin({
            page: currentPage,
            limit: candidatesPerPage,
            filter: filterStatus,
          })
        );
        setCandidates(response.candidates);
        setTotalPages(response.pagination.totalPages);
        setTotalItems(response.pagination.totalDocuments);
      } catch (error) {
        toast.error("Failed to fetch candidates");
      } finally {
        setLoading(false);
      }
    };

    fetchCandidates();
  }, [currentPage, candidatesPerPage, filterStatus, dispatch]);

  const handleRowExpand = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const handleCandidatesPerPageChange = (e) => {
    setCandidatesPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page whenever candidates per page changes
  };

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleFilterChange = (e) => {
    const status = e.target.value;
    setFilterStatus(Number(status));
    setCurrentPage(1);
  };

  const handleDropdownToggle = (index) => {
    setDropdownIndex(dropdownIndex === index ? null : index);
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 0:
        return "text-green-600";
      case 1:
        return "text-red-600";
      default:
        return "text-gray-600";
    }
  };

  // Handle edit click
  const handleEditClick = (candidate) => {
    navigate("/index/editCandidate", {
      state: { candidateId: candidate._id, email: candidate.email },
    });
  };

  // Handle enable/disable candidate
  const handleEnableDisable = async (candidateId, index) => {
    setEnableDisableLoadingIndex(index); // Show loader for enable/disable button
    try {
      await dispatch(toggleBlockUnBloack({ id: candidateId }));
      setCandidates((prevCandidates) =>
        prevCandidates.map((candidate) =>
          candidate._id === candidateId
            ? { ...candidate, isBlocked: !candidate.isBlocked }
            : candidate
        )
      );
      toast.success("Candidate status updated successfully");
    } catch (error) {
      toast.error("Failed to update candidate status");
    } finally {
      setEnableDisableLoadingIndex(null); // Reset loader for enable/disable button
      setDropdownIndex(null); // Close dropdown after action
    }
  };

  // Handle delete candidate
  const handleDelete = async (candidateId, index) => {
    setDeleteLoadingIndex(index); // Show loader for delete button
    try {
      await dispatch(softDeleteCandidate({ id: candidateId }));
      setCandidates((prevCandidates) =>
        prevCandidates.filter((candidate) => candidate._id !== candidateId)
      );
      toast.success("Candidate deleted successfully");
    } catch (error) {
      toast.error("Failed to delete candidate");
    } finally {
      setDeleteLoadingIndex(null); // Reset loader for delete button
      setDropdownIndex(null); // Close dropdown after action
    }
  };

  return (
    <div className="rounded-lg p-4 bg-white shadow">
      <div className="flex justify-between items-center mb-4">
        <div className="flex gap-2">
          <select
            className="p-2 border rounded bg-gray-100"
            onChange={handleFilterChange}
            value={filterStatus}
          >
            <option value={0}>All</option>
            <option value={1}>Enabled</option>
            <option value={2}>Disabled</option>
          </select>
        </div>
      </div>

      {loading ? (
        <ButtonLoader />
      ) : (
        <>
          {/* Desktop and Tablet */}
          <div className="hidden md:flex flex-col">
            <table className="min-w-full text-xs lg:text-sm">
              <thead>
                <tr className="text-left text-gray-400">
                  <th className="p-2">ID</th>
                  <th className="p-2">CANDIDATE</th>
                  <th className="p-2">STATUS</th>
                  <th className="p-2">ACTION</th>
                </tr>
              </thead>
              <tbody className="text-gray-700">
                {candidates.map((candidate, index) => (
                  <tr key={index} className="border-t">
                    <td className="p-2 font-bold">{candidate._id}</td>
                    <td className="p-2">
                      <div className="font-bold">
                        {candidate.first_name} {candidate.last_name}
                      </div>
                      <div className="text-gray-500">{candidate.email}</div>
                    </td>
                    <td className="p-2">
                      <span
                        className={`px-4 py-1 rounded-2xl ${getStatusClass(
                          candidate.isBlocked ? 1 : 0
                        )}`}
                      >
                        {candidate.isBlocked ? "Disabled" : "Enabled"}
                      </span>
                    </td>
                    <td className="p-2 relative">
                      <BsThreeDotsVertical
                        className="cursor-pointer"
                        onClick={() => handleDropdownToggle(index)}
                      />
                      {dropdownIndex === index && (
                        <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-10">
                          <div
                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => handleEditClick(candidate)}
                          >
                            Edit
                          </div>
                          <div
                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                            onClick={() =>
                              handleEnableDisable(candidate._id, index)
                            }
                          >
                            {enableDisableLoadingIndex === index ? (
                              <ButtonLoader />
                            ) : candidate.isBlocked ? (
                              "Enable"
                            ) : (
                              "Disable"
                            )}
                          </div>
                          <div
                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => handleDelete(candidate._id, index)}
                          >
                            {deleteLoadingIndex === index ? (
                              <ButtonLoader />
                            ) : (
                              "Delete"
                            )}
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Mobile */}
          <div className="flex md:hidden flex-col">
            <table className="min-w-full text-xs lg:text-sm">
              <thead>
                <tr className="text-left text-gray-400">
                  <th className="p-2">ID</th>
                  <th className="p-2">ACTION</th>
                </tr>
              </thead>
              <tbody className="text-gray-700">
                {candidates.map((candidate, index) => (
                  <React.Fragment key={index}>
                    <tr className="border-t">
                      <td className="p-2 font-bold">
                        <div className="flex items-center">
                          {expandedRow === index ? (
                            <div className="flex items-center">
                              <div
                                className="cursor-pointer p-1 rounded-full bg-red-500 text-white"
                                onClick={() => handleRowExpand(index)}
                              >
                                <FiMinus />
                              </div>
                            </div>
                          ) : (
                            <div className="flex items-center">
                              <div
                                className="cursor-pointer p-1 rounded-full bg-purpleButton text-white"
                                onClick={() => handleRowExpand(index)}
                              >
                                <GoPlus />
                              </div>
                            </div>
                          )}
                          <div className="ml-2">{candidate._id}</div>
                        </div>
                      </td>
                      <td className="p-2 relative">
                        <BsThreeDotsVertical
                          className="cursor-pointer"
                          onClick={() => handleDropdownToggle(index)}
                        />
                        {dropdownIndex === index && (
                          <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-10">
                            <div
                              className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => handleEditClick(candidate)}
                            >
                              Edit
                            </div>
                            <div
                              className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() =>
                                handleEnableDisable(candidate._id, index)
                              }
                            >
                              {enableDisableLoadingIndex === index ? (
                                <ButtonLoader />
                              ) : candidate.isBlocked ? (
                                "Enable"
                              ) : (
                                "Disable"
                              )}
                            </div>
                            <div
                              className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => handleDelete(candidate._id, index)}
                            >
                              {deleteLoadingIndex === index ? (
                                <ButtonLoader />
                              ) : (
                                "Delete"
                              )}
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                    {expandedRow === index && (
                      <tr>
                        <td colSpan={2} className="p-2">
                          <div className="flex flex-col space-y-2">
                            <div>
                              <div className="font-bold">CANDIDATE:</div>
                              <div>
                                {candidate.first_name} {candidate.last_name}
                              </div>
                              <div className="text-gray-500">
                                {candidate.email}
                              </div>
                            </div>
                            <div className="flex flex-col text-left gap-1">
                              <div className="font-bold">STATUS:</div>
                              <div>
                                <span
                                  className={`px-4 py-1 rounded-2xl items-center ${getStatusClass(
                                    candidate.isBlocked ? 1 : 0
                                  )}`}
                                >
                                  {candidate.isBlocked ? "Disabled" : "Enabled"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination Controls */}
          <div className="flex justify-center sm:justify-between items-center mt-4 text-sm">
            <div className="hidden sm:flex items-center space-x-2">
              <select
                className="p-2 border rounded bg-gray-100"
                onChange={handleCandidatesPerPageChange}
                value={candidatesPerPage}
              >
                <option value="2">2</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
              <span>
                Showing {(currentPage - 1) * candidatesPerPage + 1} to{" "}
                {Math.min(currentPage * candidatesPerPage, totalItems)} of{" "}
                {totalItems} records
              </span>
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => paginate(currentPage - 1)}
                className={`px-2 py-1 rounded ${
                  currentPage === 1
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-gray-200 text-gray-600"
                }`}
                disabled={currentPage === 1}
              >
                <FaAngleLeft />
              </button>
              <button
                key={currentPage}
                onClick={() => paginate(currentPage)}
                className="px-3 py-1 rounded bg-purpleButton text-white"
              >
                {currentPage}
              </button>
              <button
                onClick={() => paginate(currentPage + 1)}
                className={`px-2 py-1 rounded ${
                  currentPage === totalPages
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-gray-200 text-gray-600"
                }`}
                disabled={currentPage === totalPages}
              >
                <FaAngleRight />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Candidates;
